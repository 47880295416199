import React from "react";
import { useTranslation } from "react-i18next";

const TITLE_STYLE = "text-xs font-bold flex-center py-1";
const BORDER = "border-0 border-solid";

const WIDTH = {
    checkMan: "w-[33.3%]",
    storeMan: "w-[33.3%]",
    retrunMan: "w-[33.3%]",

};

//page-break-after: always
const Footer = () => {
    const { t } = useTranslation();


    return (
        <section className="flex flex-col w-full mb-1">
            <div className={`flex items-center flex-1 ${BORDER} border-y`}>
                <span className={` ${TITLE_STYLE} ${BORDER} ${WIDTH.checkMan} border-l`}>{t("IMS.plan-out.print_footer_use")}</span>
                <span className={` ${TITLE_STYLE} ${BORDER} ${WIDTH.storeMan} border-x`}>{t("IMS.plan-out.print_footer_wh")}</span>
                <span className={`  ${TITLE_STYLE} ${BORDER} ${WIDTH.retrunMan} border-r`}>{t("IMS.plan-out.print_footer_create")}</span>
            </div>
            <div className={`flex items-center flex-1 ${BORDER} border-b`}>
                <span className={`h-10  ${BORDER} ${WIDTH.checkMan} border-l`} />
                <span className={`h-10  ${BORDER} ${WIDTH.storeMan} border-x`} />
                <span className={`h-10  ${BORDER} ${WIDTH.retrunMan} border-r`} />
            </div>
            {/* {SING_LABEL.map((item, i) => (
              
            ))} */}
        </section>
    );
};

export default Footer;
