import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Barcode from "react-jsbarcode";

import { IconYUJULogo } from "@/components/Icon/YUJU";
// 引用打API HOOKS
import useAPI from "@/hooks/useAPI";
// 飲用後端API資料
import { getOutstockHD } from "@/service/apis/OMS/Outstock";

const TITLE_STYLE = "flex text-sm justify-between font-bold";
const VALUE_STYLE = "text-sm font-semibold pl-2";

// 將表身資料props傳進來
const Header = ({ hd_values, pages, currentPage }) => {
  const { t } = useTranslation();

  // 表頭 => hd_values
  // 表身 => s_tbData

  // 為表頭資料設置狀態
  const [s_hdData, set_s_hdData] = useState([]);

  // 設置常數管理與getOutstockHD API調用的交互，用來發起請求
  const call_getOutstockHD = useAPI(getOutstockHD);

  // 透過props傳遞的表身資料判斷來觸發向後端請求資料
  useEffect(() => {
    if (Object.keys(hd_values).length > 0) {
      call_getOutstockHD.request({ N: hd_values.woN });
    }
  }, [hd_values]);

  // 透過API的狀態來觸發判斷，將取得的資料放入s_hdData
  useEffect(() => {
    // status => load, err, suc
    const { data, status, msg } = call_getOutstockHD;
    if (status === "suc") {
      set_s_hdData(data);
    } else if (status === "err") {
      set_s_hdData([]);
    }
  }, [call_getOutstockHD.status]);

  const WIDTH = ["w-[15%]", "w-[45%]", "w-[15%]", "w-[25%]"];

  return (
    <section className="flex flex-col w-full">
      <section className="flex justify-between ">
        <div className="text-m font-bold">
          <IconYUJULogo className="text-2xl mr-1" />
          <span className="text-m font-bold ml-2">SAT</span>
        </div>
        <div>
          <span className="text-sm font-bold">{`page ${currentPage}/${pages}`}</span>
        </div>
      </section>
      <section>
        <div>
          <span className="text-[10px] font-bold">
            Kawasan Industri NEWTON TECHNO PARK JL. JATI I BLOK J5 - NO. 5 LIPPO CIKARANG - BEKASI
            Telp ( 899 - 01460 - 62 ) Fax ( 899 - 01763 )
          </span>
        </div>
        <div className="flex-center relative">
          <span className="text-[22px] font-bold tracking-wide flex flex-center mb-5">
            MATERIAL RETURN FORM
          </span>
          <div className="text-xl font-bold absolute right-0">
            <Barcode
              value={hd_values?.N}
              options={{
                displayValue: false,
                width: 1,
                height: 20,
              }}
            />
          </div>
        </div>
      </section>
      <section className="flex flex-col w-full">
        <section className="flex mb-1">
          {/* 第一行 */}
          <div className="flex w-full">
            {/* 退料單號 */}
            <span className={`${WIDTH[0]} ${TITLE_STYLE}`}>
              <span>{t("IMS.util.returnN")}</span>
              <span>:</span>
            </span>
            <span className={`${WIDTH[1]} ${VALUE_STYLE}`}>{hd_values?.jobN}</span>

            {/* 工單號 */}
            <span className={`${WIDTH[2]} ${TITLE_STYLE} `}>
              <span>{t("IMS.util.listN")}</span>
              <span>:</span>
            </span>
            <span className={`${WIDTH[3]} ${VALUE_STYLE}`}>{hd_values?.whID}</span>
          </div>
        </section>
        <section className="flex mb-1">
          {/* 第二行 */}
          <div className="flex w-full">
            {/* 申請部門(人員) */}
            <span className={`${WIDTH[0]} ${TITLE_STYLE} `}>
              <span>{t("IMS.util.apply_person")}</span>
              <span>:</span>
            </span>
            <span className={`${WIDTH[1]} ${VALUE_STYLE}`}>{s_hdData?.N}</span>
            {/* 倉庫 */}
            <span className={`${WIDTH[2]} ${TITLE_STYLE}`}>
              <span>{t("IMS.util.ware_house")}</span>
              <span>:</span>
            </span>
            <span className={`${WIDTH[3]} ${VALUE_STYLE}`}>{t("IMS.util.materialWh")}</span>
          </div>
        </section>
        <section className="flex mb-1">
          {/* 第三行 */}
          <div className="flex w-full">
            {/* 退料日期 */}
            <span className={`${WIDTH[0]} ${TITLE_STYLE} `}>
              <span>{t("IMS.util.return_date")}</span>
              <span>:</span>
            </span>
            <span className={`${WIDTH[1]} ${VALUE_STYLE}`}>{s_hdData?.N}</span>
          </div>
        </section>
      </section>
    </section>
  );
};

export default Header;
