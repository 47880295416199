import "antd/dist/reset.css";
import "dayjs/locale/en";
import "dayjs/locale/id";
import "dayjs/locale/th";
import "dayjs/locale/vi";
import "dayjs/locale/zh-cn";
import "dayjs/locale/zh-tw";

import { ConfigProvider } from "antd";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import weekday from "dayjs/plugin/weekday";
import { useEffect, useState } from "react";
import { I18nextProvider } from "react-i18next";

import i18n from "./Language/i18n";
import packageLan from "./Language/package/index";
import SystemContext from "./components/Context/SystemContext";
import MyRoutes from "./routes";
import pmsStyle from "./styles/pms";

// import pmsStyle from "./styles/pms";

// 過濾字符串 "0001-01-01"
const initFormat = dayjs.prototype.format;
dayjs.extend((filter) => {
  dayjs.prototype.format = function (e) {
    return this.isSame(dayjs("0001-01-01"), "year") ? "" : initFormat.call(this, e);
  };
});
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

function App() {
  const [c_systemConfig, set_c_systemConfig] = useState({
    auth: {},
    theme: "default",
    size: "middle",
    locale: "zh-TW",
    mode: "light",
  });
  // 可以知道現在網址是pms、oms、pds之中的哪一個，如果都不是就回傳pds
  const [s_theme, set_s_theme] = useState(pmsStyle);

  const [c_userData, set_c_userData] = useState({});

  useEffect(() => {
    i18n.changeLanguage(c_systemConfig.locale);
  }, [c_systemConfig.locale]);

  return (
    <div className="App">
      <SystemContext.Provider
        value={{
          c_systemConfig: c_systemConfig,
          set_c_systemConfig: set_c_systemConfig,
          c_userData: c_userData,
          set_c_userData: set_c_userData,
          set_s_theme: set_s_theme,
        }}
      >
        <I18nextProvider i18n={i18n}>
          <ConfigProvider
            locale={packageLan[c_systemConfig.locale]}
            componentSize={c_systemConfig.size}
            theme={s_theme}
          >
            <MyRoutes />
          </ConfigProvider>
        </I18nextProvider>
      </SystemContext.Provider>
    </div>
  );
}

export default App;
