import { ReactComponent as XlsDownload } from "@/assets/icon/Office/icon-xlsDownload.svg";
import { ReactComponent as XlsUpload } from "@/assets/icon/Office/icon-csvUpload.svg";
import { ReactComponent as Circle } from "@/assets/icon/TableIcon/circle.svg";
import { ReactComponent as Garbage } from "@/assets/icon/TableIcon/icon-delete.svg";
import { ReactComponent as Pen } from "@/assets/icon/TableIcon/icon-edit.svg";
import { ReactComponent as Lock } from "@/assets/icon/TableIcon/lock.svg";
import { ReactComponent as Unlock } from "@/assets/icon/TableIcon/unlock.svg";
import { ReactComponent as InvCheck } from "@/assets/icon/WMS/icon-check.svg";
import { ReactComponent as InvDoubleCheck } from "@/assets/icon/WMS/icon-doubleCheck.svg";
import { ReactComponent as InvThirdCheck } from "@/assets/icon/WMS/icon-thirdCheck.svg";
import { ReactComponent as InvZeroCheck } from "@/assets/icon/WMS/icon-zeroCheck.svg";
import { ReactComponent as Setting } from "@/assets/icon/WMS/setting.svg";
import { ReactComponent as Excel } from "@/assets/icon/Global/iconExcel.svg";
import { ReactComponent as OpenModal } from "@/assets/icon/Modal/iconOpenModal.svg";
import { ReactComponent as BatchImport } from "@/assets/icon/Global/IconBatchImport.svg";
import { ReactComponent as BatchADD } from "@/assets/icon/Global/IconBatchADD.svg";
import { ReactComponent as PDF } from "@/assets/icon/Adobe/IconPDF.svg";
import { ReactComponent as Analyze } from "@/assets/icon/Global/IconAnalyze.svg";
import { ReactComponent as Union } from "@/assets/icon/Global/IconUnion.svg";
import { ReactComponent as foldedit } from "@/assets/icon/Global/Iconfold_edit.svg";
import { ReactComponent as xlsDownload } from "@/assets/icon/Microsoft/IconXlsDownload.svg";
import { ReactComponent as AnalyzeII } from "@/assets/icon/Global/IconAnalyzeII.svg";
import { ReactComponent as No } from "@/assets/icon/WOS/NO.svg";
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  FileImageOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  MinusCircleOutlined,
  MoreOutlined,
  PlusCircleOutlined,
  PrinterOutlined,
  RollbackOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";

import IconBox from "./IconBox";

// table or form 新增
export const IconPlus = (props) => <IconBox component={PlusCircleOutlined} {...props} />;
// table or form 減少
export const IconMinus = (props) => <IconBox component={MinusCircleOutlined} {...props} />;
// 檔案上傳
export const IconFile = (props) => <IconBox component={FileImageOutlined} {...props} />;
// excel下載
export const IconExcelDownload = (props) => <IconBox component={XlsDownload} {...props} />;
// excel下載
export const IconExcelUpload = (props) => <IconBox component={XlsUpload} {...props} />;
// excel
export const IconExcel = (props) => <IconBox component={Excel} {...props} />;
// 編輯
export const IconPen = (props) => <IconBox component={Pen} {...props} />;
// 刪除
export const IconGarbage = (props) => <IconBox component={Garbage} {...props} />;
// 圓圈
export const IconCircle = (props) => <IconBox component={Circle} {...props} />;
// 鎖
export const IconLock = (props) => <IconBox component={Lock} {...props} />;
// 解鎖
export const IconUnlock = (props) => <IconBox component={Unlock} {...props} />;
// 勾勾
export const IconConfirm = (props) => <IconBox component={CheckCircleOutlined} {...props} />;
export const IconPDF = (props) => <IconBox component={PDF} {...props} />;
export const IconAnalyze = (props) => <IconBox component={Analyze} {...props} />;
export const IconAnalyzeII = (props) => <IconBox component={AnalyzeII} {...props} />;
export const IconXlsDownload = (props) => <IconBox component={xlsDownload} {...props} />;
export const IconUnion = (props) => <IconBox component={Union} {...props} />;
export const IconFoldedit = (props) => <IconBox component={foldedit} {...props} />;
// 藍色勾勾
export const IconCheck = (props) => (
  <IconBox component={CheckCircleFilled} className="text-[#71aff8]" {...props} />
);
// 開啟三階
export const IconOpenModal = (props) => (
  <IconBox component={OpenModal} className="text-[#71aff8]" {...props} />
);
// 印表機
export const IconPrinterOutlined = (props) => <IconBox component={PrinterOutlined} {...props} />;
// 紅色叉叉
export const IconClose = (props) => <IconBox component={CloseCircleOutlined} {...props} />;
export const IconRollbackOutlined = (props) => <IconBox component={RollbackOutlined} {...props} />;
export const IconNo = (props) => <IconBox component={No} {...props} />;
//下載
export const IconDownload = (props) => <IconBox component={DownloadOutlined} {...props} />;

export const IconBatchImport = (props) => <IconBox component={BatchImport} {...props} />;
// 盤點確認
export const IconInvZeroCheck = (props) => <IconBox component={InvZeroCheck} {...props} />;
// 初盤
export const IconInvCheck = (props) => <IconBox component={InvCheck} {...props} />;
// 複盤
export const IconInvDoubleCheck = (props) => <IconBox component={InvDoubleCheck} {...props} />;
export const IconInvThirdCheckk = (props) => <IconBox component={InvThirdCheck} {...props} />;
export const IconMoreOutlined = (props) => <IconBox component={MoreOutlined} {...props} />;
export const IconOrderedListOutlined = (props) => (
  <IconBox component={OrderedListOutlined} {...props} />
);
export const IconExpand = (props) => (
  <IconBox className="flex justify-center items-center" component={FullscreenOutlined} {...props} />
);
export const IconShrink = (props) => (
  <IconBox
    className="flex justify-center items-center"
    component={FullscreenExitOutlined}
    {...props}
  />
);

export const IconSetting = (props) => <IconBox {...props} component={Setting} />;
export const IconBatchADD = (props) => <IconBox {...props} component={BatchADD} />;
