import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Barcode from "react-jsbarcode";

import { IconYUJULogo } from "@/components/Icon/YUJU";
import useAPI from "@/hooks/useAPI";
import { getOrdHD } from "@/service/apis/OMS/Ord";

const TITLE_STYLE = "flex justify-between text-sm font-bold";
const VALUE_STYLE = "text-sm font-semibold pl-2";

const Header = ({ hd_values }) => {
  const { t } = useTranslation();
  const call_getOrdHD = useAPI(getOrdHD);

  useEffect(() => {
    call_getOrdHD.request({ opUUID: hd_values?.preopUUID });
  }, [hd_values?.opUUID]);

  const WIDTH = ["w-[20%]", "w-[30%]", "w-[25%]", "w-[25%]"];

  return (
    <section className="flex flex-col w-full">
      <span className="text-xs font-semibold">WRH - FM - L08</span>
      <section className="flex justify-around mb-2">
        <div className="flex flex-1">
          <div>
            <IconYUJULogo className="text-3xl mr-1 mt-2" />
          </div>
          <div className="flex flex-col">
            {/* 銷售發貨單 */}
            <span className="text-xl font-bold">SAT</span>
            <span className="text-xs">Newton Techno Park Lippo Cikarang. Bekasi</span>
            <span className="text-xs">
              Jl. Jati l Blok J5 No. 5 Telp. (021) 8990 1760-62, Fax. (021) 8990 1763
            </span>
          </div>
        </div>

        <div className="text-xl font-bold flex flex-col flex-center">
          <span>{t("IMS.plan-in.orderList")}</span>
          {/* <QRCode
            size={80}
            bordered={false}
            color="#000"
            // className="p-0"
            value={JSON.stringify({
              opcode: hd_values?.opcode,
              N: hd_values?.N,
              // PN: "123",
              // dealPN: "123",
            })}
          /> */}
          <Barcode
            value={`${hd_values.opcode}${hd_values.jobN}`}
            options={{
              displayValue: false,
              width: 1,
              height: 20,
            }}
          />
        </div>
      </section>

      <section className="flex w-full mb-1">
        <section className="flex flex-col w-[75%] ">
          <div className="flex w-full ">
            {/* 入庫單號 */}
            <span className={`${WIDTH[0]} ${TITLE_STYLE}`}>
              <span>入庫單號</span>
              <span>:</span>
            </span>
            <span className={`${WIDTH[1]} ${VALUE_STYLE}`}>{hd_values?.jobN}</span>
            {/* 入庫日期 */}
            <span className={`${WIDTH[0]} ${TITLE_STYLE}`}>
              <span>入庫日期</span>
              <span>:</span>
            </span>
            <span className={`${WIDTH[1]} ${VALUE_STYLE}`}>
              {hd_values?.qalT && dayjs(hd_values?.qalT).format("DD-MM-YYYY")}
            </span>
          </div>

          <div className="flex w-full ">
            {/* 客戶PO號 */}
            <span className={`${WIDTH[0]} ${TITLE_STYLE} `}>
              <span>{t("OMS.out-stock.print-dealN")}</span>
              <span>:</span>
            </span>
            <span className={`${WIDTH[1]} ${VALUE_STYLE}`}>{hd_values?.dealN}</span>
            {/* 廠商簡稱 */}
            <span className={`${WIDTH[0]} ${TITLE_STYLE} `}>
              <span>{t("IMS.plan-in.companyNM")}</span>
              <span>:</span>
            </span>
            <span className={`${WIDTH[1]} ${VALUE_STYLE}`}>{hd_values?.custalias}</span>
          </div>

          <div className="flex w-full ">
            {/* 聯絡電話 */}
            <span className={`${WIDTH[0]} ${TITLE_STYLE}`}>
              <span>{t("OMS.out-stock.print-custtel")}</span>
              <span>:</span>
            </span>
            <span className={`${WIDTH[1]} ${VALUE_STYLE}`}></span>
            {/* ERP訂單 */}
            <span className={`${WIDTH[0]} ${TITLE_STYLE}`}>
              <span>{t("OMS.out-stock.print-N13")}</span>
              <span>:</span>
            </span>
            <span className={`${WIDTH[1]} ${VALUE_STYLE}`}>{call_getOrdHD.data?.[0]?.N}</span>
          </div>
        </section>
        <section className="flex w-[25%] h-full">
          <span className={`w-[25%] ${TITLE_STYLE} px-1 border-0 border-solid border-y border-l`}>
            <span>{t("util.util.note")}</span>
            <span>:</span>
          </span>
          <span
            className={`w-[75%] ${VALUE_STYLE} overflow-hidden border-0 border-solid border-y border-r`}
            style={{ wordWrap: "break-word" }}
          >
            {hd_values?.note}
          </span>
        </section>
      </section>
    </section>
  );
};

export default Header;
