import styled from "styled-components";
import { Input, DatePicker, Button, Select } from 'antd';
import {
  CalendarOutlined,
  SearchOutlined
} from "@ant-design/icons";

const StyleRangePicker = styled(DatePicker.RangePicker)`
  
  /* .ant-picker-range:hover {
    border-color: transparent;
  } */
`;

const StyleSelect = styled(Select)`

  .ant-select-focused,
  .ant-select-focused .ant-select-selector,
  .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }
`;

export const CustomRangePicker = ({ children, ...props }) => {
  
  return (
    <Input.Group compact>
      <DatePicker.RangePicker 
        className='hover:border-gray-300' 
        suffixIcon={null} 
        {...props} 
      />
      <Button
        className='text-white bg-gray-300 hover:border-gray-300'
        icon={<CalendarOutlined />}
      />
    </Input.Group>
  );
};

export const CustomSelect = ({ children, ...props }) => {

  return (
    <StyleSelect 
      className='w-[150px]'
      {...props}
    >
      {children}
    </StyleSelect>
  );
};

export const CustomSearchButton = ({ children, ...props }) => {

  return (
    <Button
      className='bg-gray-300 text-white hover:border-transparent'
      icon={<SearchOutlined />}
      {...props}
    />
  );
};