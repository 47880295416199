import { Button, Col, Form, Input, InputNumber, Row, Space, Spin, message } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import useAPI from "@/hooks/useAPI";
import combineBase64Images from "@/pages/LMS/util/MES/combineBase64Images";
import labelBuild from "@/pages/LMS/util/MES/labelBuild";
import { getPrintModel } from "@/service/apis/LMS/LabelManage";
import { get4120Tb, tbPrint } from "@/service/apis/WMS/WMS";

const DATE_FORMAT = "YYYY-MM-DD";

const { labelPrinter, unitToPx } = labelBuild;

export default function Printer({ set_s_showModal, s_editData = {}, c_lightData = {} }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const call_get4120Tb = useAPI(get4120Tb);
	const call_tbPrint = useAPI(tbPrint);
	const call_getPrintModel = useAPI(getPrintModel);

	// values = 外面傳進來要列印的array data
	const handlePrint = async (values) => {
		if (call_getPrintModel.status === "err") {
			message.error("請檢查模板設定");
			return;
		}

		const modalsData =
			call_getPrintModel.data?.variable?.map((x) => ({
				...JSON.parse(x.variable),
			})) || [];

		const labelParams = values.map((x) => ({
			...call_getPrintModel.data,
			...x,
			width: unitToPx(call_getPrintModel.data.width, call_getPrintModel.data.unit), //要轉為px 進行運算
			height: unitToPx(call_getPrintModel.data.height, call_getPrintModel.data.unit), //要轉為px 進行運算
		}));

		console.log(labelParams);

		// 撈不到資料
		if (Object.keys(labelParams).length === 0 || modalsData.length === 0) {
			message.error("請檢查模板或關鍵文字");
			return;
		}

		let rowNormalImg = await labelPrinter(labelParams[0], modalsData, "normal"); // 生成一般條碼的圖像

		// 生成關鍵條碼的圖像
		const rowImg = [];
		for (let i = 0; i < labelParams.length; i++) {
			const rowKeyImg1 = await labelPrinter(labelParams[i], modalsData, "key");
			rowImg.push([rowKeyImg1]);
		}

		const postImgs = combineBase64Images({
			col: 1,
			imgWidth: labelParams[0].width,
			imgHeight: labelParams[0].height,
			imgGap: 0,
			normalImgGroup: rowNormalImg,
			keyImgGroup: rowImg,
			type: " ",
		}); // 將圖像進行合併處理
		try {
			// let api = "http://192.168.0.139:59487/Printer/store";
			let api = "http://localhost:59487/Printer/store";
			axios
				.post(api, {
					type: "zpl",
					SAT: postImgs,
					width: unitToPx(labelParams[0].width, labelParams[0].unit),
					height: unitToPx(labelParams[0].height, labelParams[0].unit),
					left_gap: 0,
					top_gap: 0,
					dpi: 300,
					MD: 0,
					total: rowImg.length,
					startSN: 1,
					endSN: rowImg.length,
					// angle: 0,
					MD: 15,
					top_gap: 0,
					left_gap: 0,
				})
				.then((response) => { })
				.catch((error) => {
					message.error("請條碼伺服器連線狀態");
					console.log(error);
				});
		} catch (error) {
			message.error("產生條碼失敗，請聯繫開發人員");
		}
		set_s_showModal(false);
	};

	const onSumbit = async (values) => {
		call_tbPrint.request({
			...call_get4120Tb.data,
			...values, // form values後端需要的值
		});
	};

	const calculateBatchcount = (batchbom, planqty) => {
		if (batchbom === 0 || planqty === 0) {
			return 0;
		}
		return Math.ceil(planqty / batchbom);
	};

	useEffect(() => {
		console.log("editdata", s_editData);
		call_get4120Tb.request({ ...s_editData, ...c_lightData });
		call_getPrintModel.request({ modelID: "LBL_2B_CUST_001", ver: "1" });
	}, []);

	useEffect(() => {
		if (call_get4120Tb.status === "suc") {
			const batchData = call_get4120Tb.data;
			form.setFieldsValue({
				...batchData,
				suppID: batchData.suppID + "_" + batchData.suppalias,
				date: dayjs(batchData.createT).format(DATE_FORMAT), // format日期
				batchCount: calculateBatchcount(batchData.batchbom, batchData.planqty),
			});
		}
	}, [call_get4120Tb.status]);

	useEffect(() => {
		if (call_getPrintModel.status === "err") {
			message.error(call_getPrintModel.msg);
		}
	}, [call_getPrintModel.status]);

	useEffect(() => {
		if (call_tbPrint.status === "suc") {
			const printData = call_tbPrint.data.map((x) => {
				return {
					...call_get4120Tb.data,
					...form.getFieldsValue(),
					...x,
				};
			});

			handlePrint(printData);
		}
	}, [call_tbPrint.status]);

	return (
		<Spin spinning={call_get4120Tb.status === "load"}>
			<Form form={form} layout="vertical" onFinish={onSumbit}>
				<Row gutter={[12, 12]}>
					<Col span={8}>
						<Form.Item label={t("util.util.suppID")} name="suppID">
							<Input disabled />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("util.util.pdtNM")} name="pdtNM">
							<Input disabled />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("util.util.dealPN")} name="dealPN">
							<Input disabled />
						</Form.Item>
					</Col>

					<Col span={8}>
						<Form.Item label={t("util.util.PN")} name="PN">
							<Input disabled />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("PMS.util.N23")} name="N23">
							<Input disabled />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("PMS.pro.proT")} name="date">
							<Input disabled />
						</Form.Item>
					</Col>

					<Col span={8}>
						<Form.Item label={t("IMS.util.planqty")} name="planqty" rules={[{ required: true }]}>
							<InputNumber className="w-full" disabled />
						</Form.Item>
					</Col>

					<Col span={8}>
						<Form.Item label={"Batch No"} name="batchbom" rules={[{ required: true }]}>
							<InputNumber
								className="w-full bg-[#c9dffa] focus:bg-[#c9dffa] hover:bg-[#c9dffa] active:bg-[#c9dffa]"
								onChange={(e) => {
									const formData = form.getFieldsValue();
									form.setFieldsValue({
										batchCount: calculateBatchcount(e, formData.planqty),
									});
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={"BatchCount"} name="batchCount" rules={[{ required: true }]}>
							<InputNumber className="w-full" disabled />
						</Form.Item>
					</Col>

					<Col span={24} className="flex justify-end">
						<Space>
							<Button onClick={() => set_s_showModal(false)}>{t("util.util.cancel")}</Button>
							<Button type="primary" htmlType="submit">
								{t("util.util.header_print")}
							</Button>
						</Space>
					</Col>
				</Row>
			</Form>
		</Spin>
	);
}
