import React from "react";
import { useTranslation } from "react-i18next";
import Barcode from "react-jsbarcode";
import { formatAmount } from "@/util/format";

const TITLE_STYLE = "text-[12px] font-bold flex-center border-0 border-solid border-y py-1";
const BORDER = "border-0 border-solid";

const WIDTH = {
    itemno: "w-[3%]",
    PN: "w-[20%]",
    dealPN: "w-[20%]",
    pdtNM: "w-[7%]",
    pdtspec: "w-[9%]",
    indqty: "w-[9%]",
    boxnum: "w-[32%]",
    // barcode: "w-[28%]",
};

const TbContainer = ({ hd_values, s_tbData }) => {
    const { t } = useTranslation();

    return (
        <section className="flex-1 flex flex-col">
            <div className="border-0 border-solid border-l w-full flex mb-2">
                {/* 項序 */}
                <span className={`${WIDTH.itemno} border-r ${TITLE_STYLE}`}>{t("IMS.plan-out.printN")}</span>
                <span className={`${WIDTH.PN} border-r ${TITLE_STYLE}`}>{t("util.util.PN")}</span>
                <span className={`${WIDTH.dealPN} border-r ${TITLE_STYLE}`}>{t("IMS.util.describe")}</span>
                <span className={`${WIDTH.pdtNM} border-r ${TITLE_STYLE}`}>{t("IMS.util.unit")}</span>
                <span className={`${WIDTH.pdtspec} border-r ${TITLE_STYLE}`}>{t("IMS.plan-out.printQty")}</span>
                <span className={`${WIDTH.indqty} border-r ${TITLE_STYLE}`}>{t("IMS.plan-out.printActual")}</span>
                <span className={`${WIDTH.boxnum} border-r ${TITLE_STYLE}`}>{t("IMS.plan-out.printMNB")}</span>
            </div>
            <section className={`flex flex-col ${BORDER} border-t`}>
                {s_tbData?.map((item, i) => (
                    <div key={i} className={`flex items-center w-full text-[12px] ${BORDER} border-b`}>
                        <span className={`h-7 ${WIDTH.itemno} flex-center ${BORDER} border-x`}>
                            {item.itemno}
                        </span>
                        <span className={`${WIDTH.PN} flex-start p-1`}>{item.PN}</span>
                        <span className={`h-7 ${WIDTH.dealPN} flex-center ${BORDER} border-x`}>{item.dealPN}</span>
                        <span className={`h-7 flex flex-center  ${WIDTH.pdtNM} ${BORDER} border-r`}>
                            {item.itemno}
                        </span>
                        <span className={`h-7 pr-1 flex items-center justify-end ${WIDTH.pdtspec} ${BORDER} border-r`}>
                            {formatAmount(item.planqty, 2)}
                        </span>
                        <span className={`h-7 pr-1 flex items-center justify-end ${WIDTH.indqty} ${BORDER} border-r`}>
                            {item.indqty}
                        </span>
                        <span className={`h-7 flex-center ${WIDTH.boxnum} ${BORDER} border-r`}>
                            <Barcode
                                value={`${item.PN}`}
                                options={{
                                    displayValue: false,
                                    width: 1,
                                    height: 20,
                                    margin: 0
                                }}
                            />
                        </span>
                    </div>
                ))}
            </section>
        </section>
    );
};

export default TbContainer;
