import { ReactComponent as Cancel } from "@/assets/icon/Modal/icon-cancel.svg";
import { ReactComponent as Confirm } from "@/assets/icon/Modal/icon-confirm.svg";
import { ReactComponent as Quote } from "@/assets/icon/Modal/icon-quote.svg";
import { ReactComponent as Transfer } from "@/assets/icon/Modal/icon-transfer.svg";

import IconBox from "../IconBox";

export const IconCancel = (props) => <IconBox component={Cancel} {...props} />;
export const IconConfirm = (props) => <IconBox component={Confirm} {...props} />;
export const IconQuote = (props) => <IconBox component={Quote} {...props} />;
export const IconTransfer = (props) => <IconBox component={Transfer} {...props} />;
