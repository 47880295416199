import { useState } from "react";
import { useTranslation } from "react-i18next";

interface ApiResponse {
  msg: string;
  data: any[];
  status: boolean;
}

type ApiFunction = (params: any) => Promise<ApiResponse>;

const useAPI = (apiFunc: ApiFunction) => {
  const { t } = useTranslation();
  const [data, setData] = useState<any[]>([]);
  const [msg, setMsg] = useState<any>("暫無資料");
  const [status, setStatus] = useState<"def" | "load" | "suc" | "err">("def");

  const request = async (params: any) => {
    // 只能使用經過axios包裝過後的 api
    setStatus("load");
    const res = await apiFunc(params);

    if (res?.msg) setMsg(t(res.msg));
    setData(res?.data);
    res?.status ? setStatus("suc") : setStatus("err");
  };

  return { data, status, msg, request };
};

export default useAPI;
