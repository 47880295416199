import { Button, DatePicker, Input, Select, message } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import { AddButton, SearchButton } from "@/components/PDS/Buttons";
import PageHeader from "@/components/PageHeader/index";
import useAPI from "@/hooks/useAPI";
import { getCustHd } from "@/service/apis/ADM/client";
import { PlusOutlined } from "@ant-design/icons";
import { IconPrinter } from "@/components/Icon/WOS";
import Out from "./Out";


const OPCODE_LABELS = [
	{ key: "4210", label: "IMS.util.opcode_4210" }, // 生產領用
	{ key: "4260", label: "IMS.util.opcode_4260" }, // 生產超領
	{ key: "4230", label: "報廢除帳" }, // 進料退出
];

const DATE_FORMAT = "YYYY-MM-DD";

const PlanOutManage = () => {
	const { t } = useTranslation();
	const outRef = useRef();
	const call_getCustHD = useAPI(getCustHd);


	useEffect(() => {
		call_getCustHD.request();
	}, []);

	useEffect(() => {
		const { status, msg, data } = call_getCustHD;
		if (status === "err") {
			message.error(msg);
		}
	}, [call_getCustHD.status]);
	// 要搜尋的資料
	const [c_searchData, set_c_searchData] = useState({
		opcode: "4210",
		startDate: dayjs().startOf("month").format(DATE_FORMAT),
		endDate: dayjs().endOf("month").format(DATE_FORMAT),
		lifeF: "",
	});

	const opcodeData = OPCODE_LABELS.map((x) => ({
		key: x.key,
		label: t(x.label),
		children: <Out ref={outRef} c_searchData={c_searchData} />,
	}));

	// 更改搜尋項目
	const handleChange = (type, value) => {
		set_c_searchData((prev) => {
			if (type === "time") {
				prev.startDate = dayjs(value[0]).format(DATE_FORMAT);
				prev.endDate = dayjs(value[1]).format(DATE_FORMAT);
			} else {
				prev[type] = value;
			}
			return { ...prev };
		});
	};
	// 新增
	const handleClick = () => {
		switch (c_searchData.opcode) {
			case "4210":
				outRef.current.Create();
				break;
			default:
				outRef.current.Create();
				break;
		}
	};

	const searchItem = [
		{ value: "", label: t("IMS.util.lifeF_A") },
		{ value: "0", label: t("IMS.util.lifeF_0") },
		{ value: "1", label: t("IMS.util.lifeF_1") },
		{ value: "2", label: t("IMS.util.lifeF_2") },
		{ value: "T", label: t("IMS.util.lifeF_T") },
	].map((option) => (
		<Button
			key={option.value}
			type={c_searchData.lifeF === option.value ? "primary" : "default"}
			onClick={() => set_c_searchData((prev) => ({ ...prev, lifeF: option.value }))}
		>
			{option.label}
		</Button>
	));

	return (
		<>
			<PageHeader
				title="14-I 資材出庫作業管理"
				extra={[
					...searchItem,
					<DatePicker.RangePicker
						key="time"
						allowClear={false}
						value={[dayjs(c_searchData.startDate), dayjs(c_searchData.endDate)]}
						format={DATE_FORMAT}
						onChange={(dates) => handleChange("time", dates)}
					/>,
					!OPCODE_LABELS[c_searchData.opcode] && (
						<>
							<AddButton type="primary" key="add" onClick={handleClick} />
						</>

					),
				]}
			/>
			<CustomTabs
				onChange={(activeKey) => handleChange("opcode", activeKey)}
				activeKey={c_searchData.opcode}
				items={opcodeData}
			/>

		</>
	);
};

export default PlanOutManage;
