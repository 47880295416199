import { Button, Modal, Space, message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import ShowTooltip from "@/components/CustomAntd/PDS/CustomTooltip";
import { IconCheck, IconPen } from "@/components/Icon/Action";
import PageHeader from "@/components/PageHeader/index";
import useAPI from "@/hooks/useAPI";
import { confirm, deleteTb, getTxnTb } from "@/service/apis/WMS/WMS";
import { formatAmount } from "@/util/format";
import { ExclamationCircleOutlined, FullscreenExitOutlined } from "@ant-design/icons";

import ModalContainer from "./ModalContainer";

const TB = ({ c_lightData, set_c_lightData, hdRef }) => {
  const { t } = useTranslation();

  const call_getTxnTb = useAPI(getTxnTb);
  const call_deleteTb = useAPI(deleteTb);
  const call_confirm = useAPI(confirm);

  const [s_tableData, set_s_tableData] = useState([]);

  const [s_showModal, set_s_showModal] = useState("def"); // 是否開啟 dialog

  const [s_editData, set_s_editData] = useState({});

  let countRef = useRef(null);

  const tableColumns = [
    {
      title: t("util.util.SN"), //"序",
      dataIndex: "SN",
      align: "center",
      width: "5%",
    },
    {
      title: t("util.util.PN"),
      dataIndex: "PN",
      align: "center",
      width: "15%",
    },
    {
      title: t("util.util.pdtNM"),
      dataIndex: "pdtNM",
      // width: "20%",
      render: (text) => <ShowTooltip title={text} />,
    },
    {
      title: t("util.util.whID"),
      dataIndex: "whNM",
      width: "15%",
    },
    {
      title: t("util.util.idmtype"),
      dataIndex: "idmtype",
      align: "center",
      width: "7%",
      render: (text, _, __) => {
        const keyMap = {
          S: t("util.util.idmtype_s"),
          D: t("util.util.idmtype_d"),
          M: t("util.util.idmtype_m"),
        };
        return keyMap[text];
      },
    },
    {
      title: t("IMS.util.planqty"),
      dataIndex: "planqty",
      align: "right",
      width: "7%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("util.util.tqty"), // 當前總數
      dataIndex: "tqty",
      align: "right",
      width: "7%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("util.util.sqty"), //人工輸入
      dataIndex: "sqty",
      align: "right",
      width: "7%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("util.util.dqty"), // 條碼掃入
      dataIndex: "dqty",
      align: "right",
      width: "7%",
    },
    {
      title: t("util.util.lifeF"), // 歷程
      dataIndex: "lifeF",
      align: "center",
      width: "7%",
      render: (text) => t(`IMS.util.lifeF_${text}`),
      onCell: (rowData) => ({ className: `lifeF-${rowData.lifeF}` }),
    },
    {
      title: t("util.util.action"), // 操作
      dataIndex: "render",
      align: "center",
      width: "5%",
      render: (_, rowData) => (
        <Space className=" font-size-24">
          <IconCheck
            disabled={rowData.lifeF !== "1"}
            onClick={() => call_confirm.request(rowData)}
          />
          <IconPen
            disabled={rowData.lifeF === "2" || rowData.lifeF === "T"}
            onClick={() => editData(rowData)}
          />
        </Space>
      ),
    },
  ];

  const editData = (rowData) => {
    set_s_editData(rowData);
    set_s_showModal("true");
  };

  const deleteData = (rowData) => {
    Modal.confirm({
      title: t(`IMS.util.opcode_${c_lightData.opcode}`) + "明細刪除確認",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: "請確認您希望删除此資料",
      okText: "刪除",
      onOk: () => call_deleteTb.request({ ...rowData, opcode: c_lightData.opcode }),
      cancelText: "取消",
    });
  };

  // 關閉明細資料
  const handleClose = () => {
    set_c_lightData({});
  };

  useEffect(() => {
    call_getTxnTb.request(c_lightData);
  }, [c_lightData]);

  useEffect(() => {
    if (s_showModal === "false") {
      hdRef.Call_hd();
    }
  }, [s_showModal]);

  // 取得表身資料
  useEffect(() => {
    if (call_getTxnTb.status === "suc") {
      set_s_tableData(call_getTxnTb.data);
      message.success(call_getTxnTb.msg);
    } else if (call_getTxnTb.status === "err") {
      set_s_tableData([]);
      message.error(call_getTxnTb.msg);
    }
  }, [call_getTxnTb.status]);

  // 刪除表身資料
  useEffect(() => {
    if (call_deleteTb.status === "suc") {
      message.success(call_deleteTb.msg);
      // 刪除成功後要重抓表身資料
      call_getTxnTb.request(c_lightData);
      hdRef.Call_hd();
    } else if (call_deleteTb.status === "err") {
      message.error(call_deleteTb.msg);
    }
  }, [call_deleteTb.status]);

  useEffect(() => {
    if (call_confirm.status === "suc") {
      message.success(call_confirm.msg);
      // 刪除成功後要重抓表身資料
      call_getTxnTb.request(c_lightData);
      hdRef.Call_hd();
    } else if (call_confirm.status === "err") {
      message.error(call_confirm.msg);
    }
  }, [call_confirm.status]);

  return (
    <>
      <div ref={countRef}>
        <PageHeader
          title={t(`IMS.util.opcode_${c_lightData.opcode}`)}
          extra={[
            <Button type="danger" icon={<FullscreenExitOutlined />} onClick={handleClose}>
              {t("util.util.hide_detail")}
            </Button>,
          ]}
        />
        <CustomTable
          scroll={{
            x: "max-content",
            y: window.innerHeight - 64 - 60 - 70 - 50 - 120,
          }}
          columns={tableColumns}
          // columns={tableColumns.map((item) => {
          //   item.width = `${parseInt(100 / tableColumns.length - 1)}%`;
          //   return item;
          // })}
          dataSource={s_tableData.map((item, index) => {
            return { ...item, key: index, SN: index + 1 };
          })}
          pagination={false}
        />
      </div>

      <CustomModal
        width="60%"
        title={
          t(`util.util.edit`) + t(`IMS.util.opcode_${c_lightData.opcode}`) + t(`util.util.detail`)
        }
        open={s_showModal === "true"}
        onCancel={() => set_s_showModal("false")}
      >
        <ModalContainer
          set_s_showModal={set_s_showModal}
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          call_getTxnTb={call_getTxnTb}
          c_lightData={c_lightData}
        />
      </CustomModal>
    </>
  );
};

export default TB;
