import { ReactComponent as GuideMap } from "@/assets/icon/PMS/icon-guideMap.svg";
import { ReactComponent as OverView } from "@/assets/icon/PMS/icon-overview.svg";
import { ReactComponent as Rec } from "@/assets/icon/PMS/icon-rec.svg";

import IconBox from "../IconBox";

// 回導覽
export const IconGuideMap = (props) => <IconBox component={GuideMap} {...props} />;
// 回總表
export const IconOverview = (props) => <IconBox component={OverView} {...props} />;
// 規格評估
export const IconRec = (props) => <IconBox component={Rec} {...props} />;
