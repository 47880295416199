import bwipjs from "bwip-js";
import Konva from "konva";

import { replaceItemVars, replaceVariable } from "./replaceItemVars";

/**
 * 條碼專用將數字添加逗號分隔
 * @param {number|string} x 要處理的數字
 * @returns {string|undefined} 處理後的數字字符串，如果輸入無效則返回 原本字串
 */
export function labelNumberWithCommas(x) {
  // 測試是否為一個可變成數字的字串
  const tryNumber = /^\d+(\.\d+)?$/.test(x.toString());
  if (typeof x === "number" || (typeof x === "string" && tryNumber)) {
    return String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return x;
  }
}

const unitToPx = (value, unit) => {
  switch (unit) {
    case "cm":
      return (value / 2.54) * 96;
    case "mm":
      return (value / 25.4) * 96;
    case "inch":
      return value * 96;
    default:
      return value;
  }
};
const retAns = (variable, base64) => ({
  x: variable.x,
  y: variable.y,
  length: variable.length,
  width: variable.width,
  base64: base64,
  invert: variable.invert,
});
// 繪製barcode
const createBarcodeImage = (data, ctx, type) => {
  const barcodeWidth =
    data.type === "datamatrix" || data.type === "qrcode" ? 126 : data.code128width;
  const barcodeHeight =
    data.type === "datamatrix" || data.type === "qrcode" ? barcodeWidth : data.code128height;
  const scaledWidth = barcodeWidth * data.barcodeSize;
  const scaledHeight = barcodeHeight * data.barcodeSize;
  let opts = {
    bcid: data.type,
    text: data.quoteText,
    scale: 5,
    includetext: false,
  };
  if (data.type === "datamatrix") {
    // 防止舊模板出錯 預設"20x20"
    opts.version = data.datamatrixVer || "20x20";
  }
  if (data.type === "upca" || data.type === "ean13") {
    opts["includetext"] = true;
    // 設定高度須把px 轉換成mm
    opts["height"] = (data.code128height * 25.4) / 96;
    opts["textsize"] = 9;
  }
  // 創建 barcode_canvas
  const barcode_canvas = document.createElement("canvas");
  // 使用 bwipjs 將條碼繪製到 canvas 上
  bwipjs.toCanvas(barcode_canvas, opts);
  // 將繪製好的條碼轉換為 base64 編碼的圖片資料
  const barcodeDataUrl = barcode_canvas.toDataURL("image/png", 1).split(",")[1];
  if (type === "general") {
  } else {
    return retAns(
      { x: data.x, y: data.y, width: scaledWidth, length: scaledHeight, invert: false },
      barcodeDataUrl
    );
  }
};
// 繪製圖片指定位置
const createImage = (data, ctx, type) => {
  if (type === "general") {
    const img = new Image();
    img.src = data.imgUrl;
    img.height = data.len;
    img.width = data.boxWidth;
    // 當圖像加載完成後，繪製到 canvas 上
    img.onload = function () {
      ctx.drawImage(img, data.x, data.y);
    };
  } else {
    return retAns(
      { x: data.x, y: data.y, width: data.boxWidth, length: data.len, invert: false },
      data.imgUrl.split(",")[1]
    );
  }
};
// 繪製文字
const createText = (data, ctx, type) => {
  // 計算文字的總寬度
  const calculateTotalTextWidth = (text, fontFamily, bold, fontSize) => {
    const context = document.createElement("canvas").getContext("2d");
    context.font = `${bold} ${fontSize}px ${fontFamily}`;
    return context.measureText(text).width;
  };
  // 根據 data.type 設置要繪製的文字內容
  let text = "err";

  console.log("dataobject", data);

  switch (data.type) {
    case "keyText":
      text = data.keyText;
      break;
    case "text":
      text = data.text;
      break;
    case "quoteText":
      text = data.quoteText;
      break;
  }

  console.log("text's text props", data.type, text);

  //判斷是否需要轉換數字為 100100000=> (100,100,000)
  if (data.isNumberWithCommas) {
    text = labelNumberWithCommas(text);
  }

  const totalTextWidth = calculateTotalTextWidth(text, data.fontFamily, data.bold, data.fontSize);

  const boxWidth = data.boxWidth === 0 ? totalTextWidth : data.boxWidth; // 添加 boxWidth
  let lines = [text]; // 初始化為一行

  // 檢查是否需要換行
  if (totalTextWidth > boxWidth) {
    lines = []; // 清空，準備添加多行
    let words = text.split("");
    let currentLine = words[0];
    for (let i = 1; i < words.length; i++) {
      let word = words[i];
      let width = calculateTotalTextWidth(
        currentLine + word,
        data.fontFamily,
        data.bold,
        data.fontSize
      );
      if (width < boxWidth) {
        currentLine += word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine);
  }
  const canvasText = document.createElement("canvas");
  const ctxText = canvasText.getContext("2d");
  // 提升解析度 增加分辨率
  // 提升解析度 增加分辨率
  const UPSIZE = 2;
  ctxText.scale(UPSIZE, UPSIZE);

  const lineHeight = data.fontSize * 1.2;
  const canvasHeight = lines.length * lineHeight;

  canvasText.width = boxWidth * UPSIZE;
  canvasText.height = canvasHeight * UPSIZE;

  ctxText.font = `${data.bold} ${data.fontSize * UPSIZE}px ${data.fontFamily}`;
  ctxText.fillStyle = "#000000";
  ctxText.textBaseline = "middle";

  for (let i = 0; i < lines.length; i++) {
    let linesLength = (i + 1) * lineHeight;
    let margin = (i * data.fontSize * UPSIZE) / 2;
    let xStart = 0; // 默认为左对齐
    const lineWidth = calculateTotalTextWidth(lines[i], data.fontFamily, data.bold, data.fontSize);
    // 根据 textAlign 调整 xStart
    if (data.boxAlign === "center") {
      xStart = (boxWidth - lineWidth) / 2;
    } else if (data.boxAlign === "right") {
      xStart = boxWidth - lineWidth;
    }
    ctxText.fillText(lines[i], xStart * UPSIZE, (linesLength * UPSIZE) / 2 + margin);
  }

  if (type === "general") {
  } else {
    return retAns(
      {
        x: data.x,
        y: data.y,
        width: boxWidth * (data.fontWidth / 100),
        length: canvasHeight,
        invert: data.c === "#ffffff",
      },
      canvasText.toDataURL("image/png", 1).split(",")[1]
    );
  }
};

// 繪製跟隨文字
const createFollowText = (data, ctx, type, models, variables) => {
  // 計算文字的總寬度
  const calculateTotalTextWidth = (text, fontFamily, bold, fontSize) => {
    const context = document.createElement("canvas").getContext("2d");
    context.font = `${bold} ${fontSize}px ${fontFamily}`;
    return context.measureText(text).width;
  };

  const realTexts = [];

  // 用data的"id"找modelTb"itemID"
  const getRealText = () => {
    const dataId = data.id;
    const followTextObject = models[parseInt(dataId ?? "") - 1];
    const followTextStrings = followTextObject.followText;

    const regex = /\${(\d+)[,_\s]*\d*}/g;

    const numbers = [];

    let match;
    while ((match = regex.exec(followTextStrings ?? "")) !== null) {
      numbers.push(match[1]);
    }
    console.log("numbers", numbers);

    for (const item of models) {
      const itemIdInt = parseInt(item.id ?? "");
      if (numbers.includes(itemIdInt.toString() || "")) {
        realTexts.push(item);
      }
    }
  };

  getRealText();

  let xPosition = data.x; // Initial x position based on input data
  let yPosition = data.y; // Initial y position based on input data

  // Array of 已變成Image的Text
  const textImages = [];

  const UPSIZE = 2;

  for (const textObject of realTexts) {
    const replacedTextObject = replaceItemVars(models, textObject, variables);
    console.log("textObject", replacedTextObject);
    if (
      replacedTextObject.type !== "text" &&
      replacedTextObject.type !== "keyText" &&
      replacedTextObject.type !== "quoteText"
    ) {
      console.error("Input is not Text");
      return;
    }
    const text =
      replacedTextObject.type === "text"
        ? replacedTextObject.text
        : replacedTextObject.type === "keyText"
        ? replacedTextObject.keyText
        : replacedTextObject.type === "quoteText"
        ? replacedTextObject.quoteText
        : "";

    console.log("text's text props", replacedTextObject.type, text);
    const boxWidth = calculateTotalTextWidth(
      text,
      replacedTextObject.fontFamily,
      replacedTextObject.bold,
      replacedTextObject.fontSize
    );

    let lines = [text];

    console.log("lines");

    if (
      calculateTotalTextWidth(
        text,
        replacedTextObject.fontFamily,
        replacedTextObject.bold,
        replacedTextObject.fontSize
      ) > boxWidth
    ) {
      lines = [];

      let words = text.split("");
      let currentLine = words[0];

      for (let i = 1; i < words.length; i++) {
        let word = words[i];

        let width = calculateTotalTextWidth(
          currentLine + "" + word,
          replacedTextObject.fontFamily,
          replacedTextObject.bold,
          replacedTextObject.fontSize
        );
        if (width < boxWidth) {
          currentLine += "" + word;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      }
      console.log("currentLine", currentLine);
      lines.push(currentLine);
    }

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    context.scale(UPSIZE, UPSIZE);

    const lineHeight = replacedTextObject.fontSize * 1.3;
    const canvasHeight = lines.length * lineHeight;

    canvas.width = boxWidth * UPSIZE;
    canvas.height = canvasHeight * UPSIZE;

    context.font = `${replacedTextObject.bold} ${replacedTextObject.fontSize * UPSIZE}px ${
      replacedTextObject.fontFamily
    }`;
    context.fillStyle = replacedTextObject.c;
    context.textBaseline = "middle";

    for (let i = 0; i < lines.length; i++) {
      let margin = (i * replacedTextObject.fontSize * UPSIZE) / 2;
      let linesLength = (i + 1) * lineHeight;
      let xStart = 0;

      const lineWidth = calculateTotalTextWidth(
        lines[i],
        replacedTextObject.fontFamily,
        replacedTextObject.bold,
        replacedTextObject.fontSize
      );

      if (replacedTextObject.boxAlign === "center") {
        xStart = (boxWidth - lineWidth) / 2;
      } else if (replacedTextObject.boxAlign === "right") {
        xStart = boxWidth - lineWidth;
      }
      context.fillText(lines[i], xStart * UPSIZE, (linesLength * UPSIZE) / 2 + margin);
    }

    const image = new Konva.Image({
      x: xPosition,
      y: yPosition,
      image: canvas,
      width: boxWidth * (replacedTextObject.fontWidth / 100),
      height: canvasHeight,
      draggable: true,
      id: replacedTextObject.id,
    });

    textImages.push(image);
    xPosition += boxWidth * (replacedTextObject.fontWidth / 100);
  }

  const combinedCanvasWidth = xPosition - data.x;
  const combinedCanvasHeight = Math.max(...textImages.map((img) => img.attrs.height));

  const newDiv = document.createElement("div");
  const stage = new Konva.Stage({
    container: newDiv,
    width: combinedCanvasWidth * UPSIZE,
    height: combinedCanvasHeight * UPSIZE,
  });

  let layer = new Konva.Layer();
  var layerContext = layer.getContext()._context;
  layerContext.imageSmoothingEnabled = false;

  let currentXPosition = 0;

  textImages.forEach((img, i) => {
    const width = img.width();
    const height = img.height();

    let newImage = new Konva.Image({
      x: currentXPosition,
      y: 0,
      image: img.image(),
      width: width * UPSIZE,
      height: height * UPSIZE,
      id: i,
    });

    layer.add(newImage);
    currentXPosition += img.width() * UPSIZE;
  });

  layer.draw();
  stage.add(layer);

  const dataURL = stage.toDataURL();
  if (type === "general") {
  } else {
    return retAns(
      {
        x: textImages[0].attrs.x,
        y: data.y,
        width: combinedCanvasWidth,
        length: combinedCanvasHeight,
        invert: data.c === "#ffffff",
      },
      stage.toDataURL().split(",")[1]
    );
  }
};

// 繪製形狀
const createShape = (ctx, data) => {
  ctx.beginPath(); // 開始繪製路徑
  switch (data.type) {
    case "border":
      // 繪製邊框
      ctx.moveTo(data.x, data.y); // 設定路徑起點
      const diameter = (data.angle * Math.PI) / 180; // 計算弧度
      ctx.lineTo(
        data.x + data.len * Math.cos(diameter), // 設定路徑終點 x 座標
        data.y + data.len * Math.sin(diameter) // 設定路徑終點 y 座標
      );
      ctx.strokeStyle = data.c; // 設定筆畫顏色
      ctx.lineWidth = data.b; // 設定筆畫寬度
      ctx.stroke(); // 繪製筆畫
      break;
    case "round":
      // 繪製圓形
      ctx.moveTo(data.x, data.y); // 設定路徑起點
      ctx.arc(data.x, data.y, data.radius, 0, 360); // 繪製圓弧
      ctx.fillStyle = data.c; // 設定填滿顏色
      ctx.fill(); // 填滿圖形
      break;
    case "rect":
      // 繪製方形
      ctx.strokeRect(data.x, data.y, data.boxWidth, data.len);
    default:
      break;
  }
  ctx.closePath(); // 結束繪製路徑
};
/**
 * 標籤機專用 生成標籤的函數
 * @param {Array} variables - 要替換的變數
 * @param {Array} models - 條碼模板
 * @param {string} type - 標籤類型 (預設為 "key")
 * @returns {Promise} - 回傳一個 Promise 物件，內容為生成的標籤圖片的 base64 編碼 URL
 */
const labelPrinter = async (variables, models, type = "key") => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.height = variables.height;
  canvas.width = variables.width;
  context.fillStyle = "#ffffff";
  context.fillRect(0, 0, canvas.width, canvas.height);
  const base64Images = [];

  for (const model of models) {
    // 檢查模板是否有效
    if (!model.isvalid) continue;
    context.beginPath();

    // 判斷 quoteText keyText 是否有值
    const hasQuoteText = model.quoteText || model.keyText;

    const data = replaceItemVars(models, model, variables);

    if ((type === "key" && hasQuoteText) || (type !== "key" && !hasQuoteText)) {
      // 根據標籤類型和匹配結果創建相應的圖片或形狀
      switch (data.type) {
        case "text":
        case "keyText":
        case "date":
        case "quoteText":
          base64Images.push(createText(data, undefined, ""));
          break;
        case "followText":
          base64Images.push(createFollowText(data, undefined, "", models, variables));
          break;
        case "border":
        case "round":
        case "rect":
          createShape(context, data);
          break;
        case "img":
          base64Images.push(createImage(data, undefined, ""));
          break;
        default:
          base64Images.push(createBarcodeImage(data, undefined, ""));
          break;
      }
    }
  }
  if (type !== "key") {
    // 當標籤類型不是 "key" 時，結束路徑並生成整個畫布的圖片
    context.closePath();
    const dataURL = canvas.toDataURL("image/png", 1).split(",")[1];
    base64Images.unshift(
      retAns(
        {
          x: 0,
          y: 0,
          width: variables.width,
          length: variables.height,
          invert: false,
        },
        dataURL
      )
    );
  }
  return base64Images;
};
/**
 * 通用印表機 生成標籤的函數
 * @param {Array} variables - 要替換的變數
 * @param {Array} models - 條碼模板
 * @param {string} type - 標籤類型 (預設為 "general")
 * @returns {Promise} - 回傳一個 Promise 物件，內容為生成的標籤圖片的 base64 編碼 URL
 */
const generalPrinter = async (variables, models, type = "general") => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.height = unitToPx(variables.height, variables.unit);
  canvas.width = unitToPx(variables.width, variables.unit);
  context.fillStyle = "#ffffff";
  context.fillRect(0, 0, canvas.width, canvas.height);
  for (const model of models) {
    // 檢查模板是否有效
    if (!model.isvalid) continue;
    const data = (0, replaceItemVars)(models, model, variables);
    context.beginPath();
    // 根據標籤類型和匹配結果創建相應的圖片或形狀
    switch (data.type) {
      case "text":
      case "keyText":
      case "date":
      case "quoteText":
        createText(data, context, type);
        break;
      case "followText":
        createFollowText(data, context, type, models, variables);
        break;
      case "border":
      case "round":
      case "rect":
        createShape(context, data);
        break;
      case "img":
        createImage(data, context, type);
        break;
      default:
        createBarcodeImage(data, context, type);
        break;
    }
  }
  return canvas.toDataURL("image/png", 1);
};

export default {
  labelPrinter,
  generalPrinter,
  replaceVariable,
  replaceItemVars,
  unitToPx,
};
