import { ReactComponent as TransparentBackground } from "@/assets/icon/WOS/TransparentBackground.svg";
import { ReactComponent as Act } from "@/assets/icon/WOS/icon-act.svg";
import { ReactComponent as AddQrcode } from "@/assets/icon/WOS/icon-addQrcode.svg";
import { ReactComponent as Arrow } from "@/assets/icon/WOS/icon-arrow.svg";
import { ReactComponent as GoodProduct } from "@/assets/icon/WOS/icon-goodProduct.svg";
import { ReactComponent as Printer } from "@/assets/icon/WOS/icon-labelPrinting.svg";
import { ReactComponent as Material } from "@/assets/icon/WOS/icon-materialControl.svg";
import { ReactComponent as Mhc } from "@/assets/icon/WOS/icon-mhc.svg";
import { ReactComponent as MoldTime } from "@/assets/icon/WOS/icon-moldTime.svg";
import { ReactComponent as Moon } from "@/assets/icon/WOS/icon-moon.svg";
import { ReactComponent as NgC } from "@/assets/icon/WOS/icon-ngc.svg";
import { ReactComponent as NoGoodProduct } from "@/assets/icon/WOS/icon-noGoodProduct.svg";
import { ReactComponent as Okc } from "@/assets/icon/WOS/icon-okc.svg";
import { ReactComponent as OpenT } from "@/assets/icon/WOS/icon-openT.svg";
import { ReactComponent as PdtNM } from "@/assets/icon/WOS/icon-pdtnm.svg";
import { ReactComponent as PlanQty } from "@/assets/icon/WOS/icon-planQty.svg";
import { ReactComponent as Pn } from "@/assets/icon/WOS/icon-pn.svg";
import { ReactComponent as Ppe } from "@/assets/icon/WOS/icon-ppe.svg";
import { ReactComponent as Press } from "@/assets/icon/WOS/icon-press.svg";
import { ReactComponent as ProductionAchievementRate } from "@/assets/icon/WOS/icon-productionAchievementRate.svg";
import { ReactComponent as Production } from "@/assets/icon/WOS/icon-productionControl.svg";
import { ReactComponent as ProductionPrepareTime } from "@/assets/icon/WOS/icon-productionPrepareTime.svg";
import { ReactComponent as ProductionStartTime } from "@/assets/icon/WOS/icon-productionStartTime.svg";
import { ReactComponent as ProductionTime } from "@/assets/icon/WOS/icon-productionTime.svg";
import { ReactComponent as Qty } from "@/assets/icon/WOS/icon-qty.svg";
import { ReactComponent as Quality } from "@/assets/icon/WOS/icon-qualityControl.svg";
import { ReactComponent as Scanning } from "@/assets/icon/WOS/icon-scanning.svg";
import { ReactComponent as Station } from "@/assets/icon/WOS/icon-station.svg";
import { ReactComponent as Sun } from "@/assets/icon/WOS/icon-sun.svg";
import { ReactComponent as Tar } from "@/assets/icon/WOS/icon-tar.svg";
import { ReactComponent as Triangle } from "@/assets/icon/WOS/icon-triangle.svg";
import { ReactComponent as TripDetails } from "@/assets/icon/WOS/icon-tripDetails.svg";
import { ReactComponent as Won } from "@/assets/icon/WOS/icon-won.svg";

import IconBox from "../IconBox";

export const IconTransparentBackground = (props) => (
  <IconBox component={TransparentBackground} {...props} />
);

export const IconAddQrcode = (props) => <IconBox component={AddQrcode} {...props} />;

export const IconScanning = (props) => <IconBox component={Scanning} {...props} />;

export const IconStation = (props) => <IconBox component={Station} {...props} />;

export const IconTriangle = (props) => <IconBox component={Triangle} {...props} />;

export const IconPress = (props) => <IconBox component={Press} {...props} />;

export const IconSun = (props) => <IconBox component={Sun} {...props} />;

export const IconMoon = (props) => <IconBox component={Moon} {...props} />;

export const IconArrow = (props) => <IconBox component={Arrow} {...props} />;

export const IconPrinter = (props) => <IconBox component={Printer} {...props} />;

// WpcCodeInfo Component
export const IconProductionTime = (props) => <IconBox component={ProductionTime} {...props} />;

export const IconProductionPrepareTime = (props) => (
  <IconBox component={ProductionPrepareTime} {...props} />
);

export const IconProductionStartTime = (props) => (
  <IconBox component={ProductionStartTime} {...props} />
);

export const IconMoldTime = (props) => <IconBox component={MoldTime} {...props} />;

export const IconProductionAchievementRate = (props) => (
  <IconBox component={ProductionAchievementRate} {...props} />
);

export const IconTripDetails = (props) => <IconBox component={TripDetails} {...props} />;

export const IconProduction = (props) => <IconBox component={Production} {...props} />;
export const IconMaterial = (props) => <IconBox component={Material} {...props} />;
export const IconQuality = (props) => <IconBox component={Quality} {...props} />;

export const IconGoodProduct = (props) => <IconBox component={GoodProduct} {...props} />;
export const IconNoGoodProduct = (props) => <IconBox component={NoGoodProduct} {...props} />;

export const IconWon = (props) => <IconBox component={Won} {...props} />;

export const IconPn = (props) => <IconBox component={Pn} {...props} />;

export const IconPdtNM = (props) => <IconBox component={PdtNM} {...props} />;

export const IconPlanQty = (props) => <IconBox component={PlanQty} {...props} />;

export const IconOpenT = (props) => <IconBox component={OpenT} {...props} />;

export const IconMhc = (props) => <IconBox component={Mhc} {...props} />;

export const IconNgC = (props) => <IconBox component={NgC} {...props} />;

export const IconOkc = (props) => <IconBox component={Okc} {...props} />;

export const IconAct = (props) => <IconBox component={Act} {...props} />;

export const IconPpe = (props) => <IconBox component={Ppe} {...props} />;

export const IconQty = (props) => <IconBox component={Tar} {...props} />;

export const IconTar = (props) => <IconBox component={Qty} {...props} />;
