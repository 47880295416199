import React from "react";
import { useTranslation } from "react-i18next";

const TITLE_STYLE = "text-xs font-bold flex-center py-1";
const BORDER = "border-0 border-solid";

const WIDTH = {
    checkMan: "w-[23%]",
    storeMan: "w-[35%]",
    retrunMan: "w-[42%]",

};

//page-break-after: always
const Footer = () => {
    const { t } = useTranslation();

    const SING_LABEL = [
        { title: "審核人員" },
        { title: "倉管人員" },
        { title: "領料人員" },

    ];
    return (
        <section className="flex flex-col w-full mb-1">
            <div className={`flex items-center flex-1 ${BORDER} border-y`}>
                <span className={` ${TITLE_STYLE} ${BORDER} ${WIDTH.checkMan} border-l`}>{t("IMS.util.auditors")}</span>
                <span className={` ${TITLE_STYLE} ${BORDER} ${WIDTH.storeMan} border-x`}>{t("IMS.util.wh_person")}</span>
                <span className={`  ${TITLE_STYLE} ${BORDER} ${WIDTH.retrunMan} border-r`}>{t("IMS.util.return_person")}</span>
            </div>
            <div className={`flex items-center flex-1 ${BORDER} border-b`}>
                <span className={`h-10  ${BORDER} ${WIDTH.checkMan} border-l`} />
                <span className={`h-10  ${BORDER} ${WIDTH.storeMan} border-x`} />
                <span className={`h-10  ${BORDER} ${WIDTH.retrunMan} border-r`} />
            </div>
            {/* {SING_LABEL.map((item, i) => (
              
            ))} */}
        </section>
    );
};

export default Footer;
