import { Button, Space, message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { IconConfirm, IconPen } from "@/components/Icon/Action";
import PageHeader from "@/components/PageHeader/index";
import useAPI from "@/hooks/useAPI";
import { confirm, deleteTb, getTxnTb } from "@/service/apis/WMS/WMS";
import { formatAmount } from "@/util/format";
import { FullscreenExitOutlined } from "@ant-design/icons";

import ModalContainer from "./ModalContainer";

const TB = ({ c_lightData, set_c_lightData, hdRef, c_searchData }) => {
  const { t } = useTranslation();

  const call_getTxnTb = useAPI(getTxnTb);
  const call_deleteTb = useAPI(deleteTb);
  const call_confirm = useAPI(confirm);

  const [s_tableData, set_s_tableData] = useState([]);

  const [s_showModal, set_s_showModal] = useState(false); // 是否開啟 dialog

  const [s_editData, set_s_editData] = useState({});

  const tableColumns = [
    {
      title: t("util.util.SN"), //序,
      dataIndex: "SN",
      align: "center",
      width: "3%",
    },
    {
      title: t("util.util.PN"), //料號
      dataIndex: "PN",
      width: "15%",
      align: "center",
    },
    {
      title: t("util.util.dealPN"), //客戶料號
      dataIndex: "dealPN",
      width: "15%",
      align: "center",
    },
    {
      title: t("util.util.pdtNM"), //品名
      dataIndex: "pdtNM",
      width: "20%",
      align: "center",
    },
    {
      title: t("util.util.whID"), //倉別
      dataIndex: "whID",
      align: "center",
      render: (_, record) => `${record.whID} -${record.whNM}`,
      width: "10%",
    },
    {
      title: t("util.util.idmtype"), //過帳型態
      dataIndex: "idmtype",
      align: "center",
      render: (text, rowData, index) => {
        const keyMap = {
          S: t("util.util.idmtype_s"),
          D: t("util.util.idmtype_d"),
          M: t("util.util.idmtype_m"),
        };
        return keyMap[text];
      },
    },
    {
      title: t("util.util.batchno"), //批號
      dataIndex: "batchno",
      align: "center",
    },
    {
      title: t("util.util.planqty"), //計畫數量
      dataIndex: "planqty",
      align: "right",
      render: (text) => formatAmount(text, 2),
    },
    {
      title: t("util.util.tqty"), //當前總數
      dataIndex: "tqty",
      align: "right",
      render: (text) => formatAmount(text, 2),
    },
    {
      title: t("util.util.sqty"), //人工輸入
      dataIndex: "sqty",
      align: "right",
      render: (text) => formatAmount(text, 2),
    },
    {
      title: t("util.util.dqty"), //條碼掃入
      dataIndex: "dqty",
      align: "right",
      render: (text) => formatAmount(text, 2),
    },
    {
      title: t("util.util.lifeF"), //歷程
      dataIndex: "lifeF",
      align: "center",
      render: (text) => t(`IMS.util.lifeF_${text}`),
      onCell: (rowData) => ({ className: `lifeF-${rowData.lifeF}` }),
    },
    {
      title: t("util.util.action"), //操作
      dataIndex: "render",
      align: "center",
      render: (_, rowData) => (
        <Space className=" font-size-24">
          <IconConfirm
            disabled={rowData.lifeF !== "1"}
            onClick={() => call_confirm.request({ ...rowData, opcode: c_searchData.opcode })}
          />
          <IconPen
            disabled={rowData.lifeF === "2" || rowData.lifeF === "T"}
            onClick={() => editData(rowData)}
          />
        </Space>
      ),
    },
  ];

  const editData = (rowData) => {
    set_s_editData(rowData);
    set_s_showModal(true);
  };

  // 關閉明細資料
  const handleClose = () => {
    set_c_lightData({});
  };

  useEffect(() => {
    call_getTxnTb.request(c_lightData);
  }, [c_lightData]);

  // 取得表身資料
  useEffect(() => {
    if (call_getTxnTb.status === "suc") {
      set_s_tableData(call_getTxnTb.data.map((x, i) => ({ ...x, key: i, SN: i + 1 })));
    } else if (call_getTxnTb.status === "err") {
      set_s_tableData([]);
      message.error(call_getTxnTb.msg);
    }
  }, [call_getTxnTb.status]);

  // 刪除表身資料
  useEffect(() => {
    if (call_deleteTb.status === "suc") {
      message.success(call_deleteTb.msg);
      // 刪除成功後要重抓表身資料
      call_getTxnTb.request(c_lightData);
      hdRef.Call_hd();
    } else if (call_deleteTb.status === "err") {
      message.error(call_deleteTb.msg);
    }
  }, [call_deleteTb.status]);

  useEffect(() => {
    if (call_confirm.status === "suc") {
      message.success(call_confirm.msg);
      call_getTxnTb.request(c_lightData);
      hdRef.Call_hd();
    } else if (call_confirm.status === "err") {
      message.error(call_confirm.msg);
    }
  }, [call_confirm.status]);

  return (
    <>
      <PageHeader
        title={t(`IMS.util.opcode_${c_lightData.opcode}`) + " " + t(`util.util.detail`)}
        extra={[
          <Button key="hidden" danger icon={<FullscreenExitOutlined />} onClick={handleClose}>
            {t("util.util.hide_detail")}
          </Button>,
        ]}
      />
      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        tbMode={Object.keys(c_lightData).length > 0}
        pagination={false}
      />

      <CustomModal
        width="60%"
        title={
          t(`util.util.edit`) + t(`IMS.util.opcode_${c_lightData.opcode}`) + t(`util.util.detail`)
        }
        open={s_showModal}
        onCancel={() => set_s_showModal(false)}
        afterClose={() => {
          hdRef.Call_hd();
        }}
      >
        <ModalContainer
          set_s_showModal={set_s_showModal}
          s_editData={s_editData}
          c_lightData={c_lightData}
        />
      </CustomModal>
    </>
  );
};

export default TB;
