import "./table.css";

import { Button, DatePicker, Input, Select, Space, Spin, Table, Typography, message } from "antd";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

// context
import { ERPContext } from "@/components/Context/SystemContext";
import SystemContext from "@/components/Context/SystemContext";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { IconExcelDownload } from "@/components/Icon/Action";
import { SearchButton } from "@/components/PDS/Buttons";
// util component
import PageHeader from "@/components/PageHeader";
import useAPI from "@/hooks/useAPI";
//API
import { apDetail, exportApDetail, getPaginatedApDetail } from "@/service/apis/PMS/Report";
//util
import { calculateAmount, formatAmount, formatAmountWithCurrency } from "@/util/format"; //數字加上千分位
import { CompressOutlined, ExpandOutlined } from "@ant-design/icons";
import { CustomSelect } from "@/components/ERP/TabExtra";
import filterOption from "@/util/filterOption";

const { Text } = Typography;

//進貨彙總表
const InventoryDetail = () => {
	const { RangePicker } = DatePicker;

	const { c_supps } = useContext(ERPContext); //從ERPContext拿到廠商資料
	const { c_systemConfig } = useContext(SystemContext);
	const { t } = useTranslation();

	const call_getApDetail = useAPI(apDetail); // useAPI 取得訂單進貨狀態資料

	const call_getPaginatedApDetail = useAPI(getPaginatedApDetail)

	const [s_OriData, set_s_OriData] = useState([]); //api拿到的原始資料

	// const [s_DetailData, set_s_DetailData] = useState([])

	const [s_TableData, set_s_TableData] = useState([]); //放到表格的資料

	const [is_spin, set_is_spin] = useState(false);

	const [is_expanded_AllRow, set_is_expanded_AllRow] = useState(false); //是否展開所有子表格

	let { group } = useParams(); //拿到路由參數，用來當作組成群組的條件

	const call_exportApDetail = useAPI(exportApDetail);

	const [s_pageHeader_Name, set_pageHeader_Name] = useState(""); //頁面標題名稱

	const [dateRangeType, setDateRangeType] = useState("month"); //搜尋區間: 當日、當週、當月

	const [expandedRowKeys, setExpandedRowKeys] = useState([]); //子表格展開行,因為展開行的索引會被記住，所以每次拿到資料都要重新設定展開行的索引為空陣列

	const [total_Info, set_total_Info] = useState({
		sumtotal: 0, //總項金額
		tax: 0, //總項稅額
		total: 0, //應收含稅總金額
	}); //所有總計

	const placeholder_map = {
		suppalias: t("util.util.placeholder_input") + t("util.util.suppalias"),
		N23: t("util.util.placeholder_input") + t("PMS.util.N23"),
		pdtNM: t("util.util.placeholder_input") + t("util.util.pdtNM"),
		shippingDealN: t("util.util.placeholder_input") + t("PMS.instock.shippingDealN"),
	};


	//搜尋資料
	const [s_searchData, set_s_searchData] = useState({
		suppID: ["All"], //廠商ID
		startDate: dayjs().startOf("month").format("YYYY-MM-DD"), // 開始日期
		endDate: dayjs().endOf("month").format("YYYY-MM-DD"), // 結束日期
	});

	const [tableHeight, setTableHeight] = useState(0);

	const updateTableHeight = () => {
		setTableHeight(window.innerHeight * 0.7);
	};

	useEffect(() => {
		updateTableHeight();
		window.addEventListener("resize", updateTableHeight);
		return () => {
			window.removeEventListener("resize", updateTableHeight);
		};
	}, [tableHeight]);

	const tableColumns = [
		{
			title: t("util.util.supp"), // 廠商簡稱
			dataIndex: "suppalias",
			align: "left",
			width: "20%",
			// render: (text, record) => {
			// 	return <span>{`${record.suppID} - ${text}`}</span>;
			// 	// return record.hasOwnProperty("opcode") ? <span>{`${record.suppID} - ${text}`}</span> : null;
			// },
		},
		{
			title: t("PMS.util.N23"), // 單號
			dataIndex: "N23",
			align: "center",
			width: "20%",
		},
		{
			title: "",
			dataIndex: "null",
			align: "center",
			width: "60%",
		},

		// {
		//   title: t("OMS.util.subtotal"),
		//   dataIndex: "count",
		//   align: "center",
		//   width: "20%",
		//   render: () => {
		//     return <span className={"text-black font-semibold"}>{t("OMS.util.subtotal")}</span>;
		//   },
		// },
		// {
		// 	title: t("util.util.currencytype"),
		// 	dataIndex: "currencytype",
		// 	align: "center",
		// 	width: "10%",
		// },
		// {
		// 	title: "",
		// 	dataIndex: "null",
		// 	align: "center",
		// 	width: "6.5%",
		// },
		// {
		// 	title: t("OMS.ap-manage.ap_sumtotal"), // 總項金額
		// 	dataIndex: "sumtotal",
		// 	align: "right",
		// 	width: "7%",
		// 	// className: "bg-green-500",
		// 	render: (text, record) => {
		// 		return (
		// 			<span className={"text-black font-semibold"}>
		// 				{formatAmountWithCurrency(text, record.currencytype)}
		// 			</span>
		// 		);
		// 	},
		// },
		// {
		// 	title: "",
		// 	dataIndex: "null",
		// 	align: "center",
		// 	width: "3%",
		// },
		// {
		// 	title: t("util.util.tax"), // 總項稅額
		// 	dataIndex: "tax",
		// 	align: "right",
		// 	width: "8%",
		// 	// className: "bg-red-900",
		// 	render: (text, record) => {
		// 		return (
		// 			<span className={"text-black font-semibold"}>
		// 				{formatAmountWithCurrency(text, record.currencytype)}
		// 			</span>
		// 		);
		// 	},
		// },
		// {
		// 	title: "",
		// 	dataIndex: "null",
		// 	align: "center",
		// 	width: "4%",
		// },
		// {
		// 	title: t("PMS.ap-manage.ap_total"), // 應付含稅總金額
		// 	dataIndex: "total",
		// 	align: "right",
		// 	// className: "bg-red-500",
		// 	width: "8%",
		// 	render: (text, record) => {
		// 		return (
		// 			<span className={"text-black font-semibold"}>
		// 				{formatAmountWithCurrency(text, record.currencytype)}
		// 			</span>
		// 		);
		// 	},
		// },
		// {
		// 	title: "",
		// 	dataIndex: "null",
		// 	align: "center",
		// 	width: "5%",
		// },
	];

	const subColumns = [
		{
			title: t("PMS.util.InstockNo"), // 單號
			dataIndex: "jobN",
			align: "center",
			width: "8%",
		},
		{
			title: t("PMS.util.N24"), // 單號
			dataIndex: "N24",
			align: "center",
			width: "8%",
		},
		{
			title: t("PMS.util.poN"),
			dataIndex: "N23",
			isExcel: true,
			align: "center",
			width: "8%",
		},
		{
			title: t("PMS.instock.shippingDealN"),
			dataIndex: "shippingDealN",
			align: "center",
			width: "8%",
		},
		{
			title: t("PMS.pro.dealN"),
			dataIndex: "dealN",
			align: "center",
			width: "8%",
		},
		{
			title: t("util.util.pdtNM"),
			dataIndex: "pdtNM",
			align: "center",
			width: "8%",
		},
		{
			title: t("util.util.PN"), // ERP料號
			dataIndex: "PN",
			align: "center",
			width: "8%",
			// className: "bg-red-500",
		},
		{
			title: t("util.util.dealPN"), // 交易料號
			dataIndex: "dealPN",
			align: "center",
			width: "8%",
		},
		{
			title: t("OMS.report.createT"), // 異動日期
			dataIndex: "lifeFT",
			align: "center",
			width: "8%",
			render: (text) => dayjs(text).format("YYYY-MM-DD"),
		},
		{
			title: t("PMS.pro.buyQty"), //"採購數量",
			dataIndex: "confirmqty",
			align: "right",
			width: "5%",
			// render: (text, record) => {
			// 	return (
			// 		<span className={`${record.hasOwnProperty("purchaseQty") ? null : "text-black font-semibold"}`}>
			// 			{formatAmount(text)}
			// 		</span>
			// 	);
			// },
		},
		{
			title: t("PMS.util.realQty"), //"異動數量",
			dataIndex: "tqty",
			align: "right",
			width: "5%",
			render: (text, record) => {
				return (
					<span className={`${record.hasOwnProperty("N") ? null : "text-black font-semibold"}`}>
						{formatAmount(text)}
					</span>
				);
			},
		},
		{
			title: t("PMS.Unpurchase.purchased"), //"已進貨",
			dataIndex: "receivedQty",
			align: "right",
			width: "5%",
		},
		{
			title: t("PMS.Unpurchase.unpurchased"), //"未進貨",
			dataIndex: "notReceivedQty",
			align: "right",
			width: "6%",
		},
	];

	// 切換搜尋資料 attr:傳入變動的欄位屬性 value:傳入變動的欄位值
	const handle_SearchData_Change = (attr, value) => {
		let updatedData = { ...s_searchData };

		if (attr === "time") {
			updatedData.startDate = value[0];
			updatedData.endDate = value[1];
		} else {
			updatedData[attr] = value;
		}

		// 如果搜尋文字為空，清空搜尋欄位
		if (attr === "searchValue" && value === "") {
			updatedData.searchFlag = undefined;
		}

		set_s_searchData(updatedData);
	};

	// 搜尋資料
	const handleClick = () => {
		const { searchFlag, searchValue, ...restData } = s_searchData;
		let tmp = {
			...restData,
			suppID: s_searchData.suppID.join("|"), // 將廠商陣列轉成字串，用直槓隔開
			searchFlag: searchValue === "" ? undefined : searchFlag,
		};

		if (searchValue !== "" && searchFlag) {
			tmp[searchFlag] = searchValue; // 整理key跟value給後端
		}

		setDateRangeType(null);
		set_is_expanded_AllRow(false);
		call_getApDetail.request(tmp);
	};

	const handleDateRangeTypeChange = (type) => {
		setDateRangeType(type);
		call_getApDetail.request({
			...s_searchData,
			suppID: s_searchData.suppID.join("|"),
			startDate: dayjs().startOf(type).format("YYYY-MM-DD"), // 開始日期
			endDate: dayjs().endOf(type).format("YYYY-MM-DD"), // 結束日期
		});
		set_s_searchData({
			...s_searchData,
			startDate: dayjs().startOf(type).format("YYYY-MM-DD"),
			endDate: dayjs().endOf(type).format("YYYY-MM-DD"),
		});
	};

	// 將CountArr資料組成要的格式再塞到表格
	const handleTableData = (data) => {
		let new_tableData = [];

		data.forEach((item) => {
			let new_obj = {};
			let childrenItem = item.slice(0, -1); //除了合計以外的資料
			new_obj = { ...item[0], ...item[item.length - 1], childrenItem: [...childrenItem] }; //把合計以外的物件變成childrenItem
			new_tableData.push(new_obj);
		});

		return new_tableData;
	};

	const pageHeader_Name_map = {
		"during": t("util.util.during"),
		"salesPerson": t("util.util.salesPerson"),
		"supp": t("util.util.supp"),
	};

	// 渲染子表格的函数
	const expandedRowRender = (record) => {
		// 使用record的某个属性作为子表格的数据源
		const subData = record.childrenItem; // 假设subItems是包含子表格数据的属性

		return (
			<Table
				className="subTable"
				rowKey={(record) => record.key}
				columns={subColumns}
				dataSource={subData}
				pagination={false}
				scroll={{ y: 560 }} // 設定高度以顯示滾動條
			/>
		);
	};

	//CALL API從後端導出EXCEL
	const exportToExcel = () => {
		console.log("exportToExcel => ", {
			...s_searchData,
			pageHeader_Name: pageHeader_Name_map[s_pageHeader_Name],
			suppID: s_searchData.suppID.join("|"),
			lang: c_systemConfig.locale,
		});

		call_exportApDetail.request({
			...s_searchData,
			pageHeader_Name: pageHeader_Name_map[s_pageHeader_Name],
			suppID: s_searchData.suppID.join("|"),
			lang: c_systemConfig.locale,
		});
	};

	//設定子表格全部展開或收合
	const handleExpandedRowKeys = () => {
		if (is_expanded_AllRow) {
			setExpandedRowKeys([]);
			set_is_expanded_AllRow(false);
		} else {
			const keys = s_TableData.map((_, index) => index + 1);
			setExpandedRowKeys(keys);
			set_is_expanded_AllRow(true);
		}
	};

	useEffect(() => {
		const { status, msg, data } = call_exportApDetail;
		if (status === "suc") {
			message.success(msg);
			console.log("data", data);
			const link = document.createElement("a");

			link.target = "_blank";
			link.href = data;
			console.log("link.href = ", link.href);

			document.body.appendChild(link);
			// return
			link.click();
			document.body.removeChild(link);
		}
		if (status === "err") {
			message.error(msg);
		}
	}, [call_exportApDetail.status]);

	useEffect(() => {
		call_getApDetail.request(s_searchData);
	}, []);

	//根據group來決定母表跟子表的欄位應該要顯示哪些，以及組成群組的條件
	useEffect(() => {
		if (group === "during") {
			set_pageHeader_Name("during");
		}
		if (group === "driNM") {
			set_pageHeader_Name("salesPerson");
		}
		if (group === "suppalias") {
			set_pageHeader_Name("supp");
		}
	}, [group]);

	// useEffect(() => {
	// 	const { status, msg, data } = call_getPaginatedApDetail;
	// 	if (status === "suc") {
	// 		set_s_DetailData(data);
	// 		setExpandedRowKeys([]);
	// 		message.success(msg);
	// 	} else if (status === "err") {
	// 		set_s_DetailData([]);
	// 		setExpandedRowKeys([]);
	// 		message.error(msg);
	// 	}
	// }, [call_getPaginatedApDetail.status]);

	//所有訂單表頭資料
	useEffect(() => {
		const { status, msg, data } = call_getApDetail;
		if (status === "suc") {
			set_s_OriData(data);
			setExpandedRowKeys([]);
			message.success(msg);
		} else if (status === "err") {
			set_s_OriData([]);
			setExpandedRowKeys([]);
			message.error(msg);
		}
	}, [call_getApDetail.status]);

	useEffect(() => {
		if (call_getApDetail.status === "load") {
			set_is_spin(true);
		} else {
			set_is_spin(false);
		}
	}, [call_getApDetail.status]);

	//拿到原始資料後做分組，將資料組成要的格式再塞到表格
	useEffect(() => {
		if (s_OriData.length === 0) {
			set_s_TableData([]);
			set_total_Info({
				sumtotal: 0, //總項金額
				tax: 0, //總項稅額
				total: 0, //應收含稅總金額
			});
			return;
		}
		//group 方法.
		const groupResult = s_OriData.reduce((accumulator, currentValue) => {
			if (!accumulator[currentValue?.["N23"]]) {
				accumulator[currentValue?.["N23"]] = [];
			}
			accumulator[currentValue?.["N23"]].push(currentValue);
			return accumulator;
		}, {});
		console.log("groupResult = ", groupResult);

		//取出各群組中的值
		const groupArr = Object.values(groupResult);

		//-----------------加上合併欄位數、總計列-------------------------------------
		let count_total_Info = {
			sumtotal: 0, //總項金額
			tax: 0, //總項稅額
			total: 0, //應收含稅總金額
		};
		const CountArr = groupArr.map((item) => {
			const count = item.reduce(
				(accumulator, currentValue) => {
					const sumtotal = calculateAmount(
						currentValue.itemsum,
						currentValue.currencytype,
						currentValue.exchangerate
					);
					const tax = calculateAmount(
						currentValue.itemsumtax,
						currentValue.currencytype,
						currentValue.exchangerate
					);
					const total = calculateAmount(
						currentValue.subtotal,
						currentValue.currencytype,
						currentValue.exchangerate
					);

					accumulator.sumtotal += sumtotal;
					accumulator.tax += tax;
					accumulator.total += total;

					count_total_Info.sumtotal += sumtotal;
					count_total_Info.tax += tax;
					count_total_Info.total += total;
					// accumulator.sumtotal += currentValue.sumtotal;
					// accumulator.tax += currentValue.tax;
					// accumulator.total += currentValue.total;
					// count_total_Info.sumtotal += currentValue.sumtotal;
					// count_total_Info.tax += currentValue.tax;
					// count_total_Info.total += currentValue.total;
					return accumulator;
				},
				{
					N: "總計",
					sumtotal: 0, //總項金額
					tax: 0, //總項稅額
					total: 0, //應收含稅總金額
				}
			);

			set_total_Info({ ...count_total_Info });
			item.push(count); //加上合計欄
			// item[0].rowSpan = item.length; //第一筆資料加上合併欄位數字

			return item;
		});

		const finalArr = [].concat(...CountArr); //CountArr = [arr(2),arr(3),arr(1)]，用contact才可以接成一個陣列
		console.log("finalArr", finalArr);
		//將OPCODE從1710、1720改成國內、國外銷貨單，若是總計欄則保持空值
		const replace_opcode = finalArr.map((item, index) => {
			let new_opcode = null;
			if (item.opcode === "1710") {
				new_opcode = "國內銷貨";
			} else if (item.opcode === "1720") {
				new_opcode = "國外銷貨";
			} else {
				new_opcode = null;
			}
			return { ...item, opcode: new_opcode, key: index };
		}); //加上key值
		console.log("replace_opcode", replace_opcode);
		// set_s_TableData(finalArr);
		set_s_TableData(handleTableData(CountArr));

		console.log("finalArr", finalArr);
	}, [s_OriData]);


	return (
		<>
			<div className="flex flex-col w-full justify-between relative">
				<Spin spinning={is_spin}>
					<div className="flex flex-col w-full justify-between">
						{/*表頭*/}
						<PageHeader
							title={`6-${s_pageHeader_Name === "during" ? "I7" : "I8"} ${t("PMS.util.InstockDetail")}_${t(`util.util.${s_pageHeader_Name}`)}`}
						/>
						<Space className="flex justify-end gap-2 mb-2">
							{/* <Text>{t("util.util.PN")} :</Text> */}
							<Input
								name="PN"
								placeholder={`${t("util.util.PN")} | ${t("util.util.dealPN")}`}
								value={s_searchData.PN}
								onChange={(e) => handle_SearchData_Change("PN", e.target.value)}
								allowClear
								className="w-[180px]"
							/>
							{/* <Text>{t("PMS.pro.dealN")} :</Text> */}
							<Input
								name="dealN"
								placeholder={`${t("PMS.pro.dealN")}`}
								value={s_searchData.dealN}
								onChange={(e) => handle_SearchData_Change("dealN", e.target.value)}
								allowClear
								className="w-[180px]"
							/>
							<Input.Group compact>
								<CustomSelect
									value={s_searchData.searchFlag}
									placeholder={t("util.util.search")}
									onChange={(value) => handle_SearchData_Change("searchFlag", value)}
									options={[
										{ value: "suppalias", label: t("util.util.suppalias") },
										{ value: "N23", label: t("PMS.util.N23") },
										{ value: "pdtNM", label: t("util.util.pdtNM") },
										{ value: "shippingDealN", label: t("PMS.instock.shippingDealN") },
									]}
								/>

								<Input
									value={s_searchData.searchValue}
									className="w-[180px]"
									placeholder={placeholder_map[s_searchData.searchFlag]}
									onChange={(e) => handle_SearchData_Change("searchValue", e.target.value)}
									allowClear
								/>

							</Input.Group>
							{/* <Input
								name="suppalias"
								placeholder={`${t("util.util.placeholder_input")}${t("util.util.suppalias")}`}
								value={s_searchData.suppalias}
								onChange={(e) => handle_SearchData_Change("suppalias", e.target.value)}
								allowClear
								className="w-[180px]"
							/>
							<Input
								name="N23"
								placeholder={`${t("util.util.placeholder_input")}${t("PMS.util.N23")}`}
								value={s_searchData.N23}
								onChange={(e) => handle_SearchData_Change("N23", e.target.value)}
								allowClear
								className="w-[180px]"
							/> */}

							{/* <Text>{t("util.util.dealPN")} :</Text> */}
							{/* <Input
								name="dealPN"
								placeholder={`${t("util.util.placeholder_input")}${t("util.util.dealPN")}`}
								value={s_searchData.dealPN}
								onChange={(e) => handle_SearchData_Change("dealPN", e.target.value)}
								allowClear
								className="w-[180px]"
							/> */}
							{/* <Text>{t("util.util.pdtNM")} :</Text> */}
							{/* <Input
								name="pdtNM"
								placeholder={`${t("util.util.placeholder_input")}${t("util.util.pdtNM")}`}
								value={s_searchData.pdtNM}
								onChange={(e) => handle_SearchData_Change("pdtNM", e.target.value)}
								allowClear
								className="w-[180px]"
							/> */}

							{/* <Input
								name="shippingDealN"
								placeholder={`${t("util.util.placeholder_input")}${t("PMS.instock.shippingDealN")}`}
								value={s_searchData.shippingDealN}
								onChange={(e) => handle_SearchData_Change("shippingDealN", e.target.value)}
								allowClear
								className="w-[180px]"
							/>  */}
							{/*廠商選擇*/}
							{/* <Text className={`${group === "suppalias" ? null : "hidden"}`}>
								{" "}
								{t("util.util.supp")} :{" "}
							</Text> */}
							{/* <Select
								className={`w-[400px] ${group !== "suppalias" ? "hidden" : null}`}
								name="suppID"
								mode="multiple"
								defaultValue="All"
								showSearch
								allowClear
								placeholder={t("util.util.placeholder_select")}
								onChange={(value) => handle_SearchData_Change("suppID", value)}
								filterOption={(input, option) => {
									return ((option?.value ?? "") + (option?.label ?? ""))
										.toLowerCase()
										.includes(input.toLowerCase());
								}}
								options={c_supps.options?.reduce(
									(accumulator, option) => {
										accumulator.push({
											label: `${option.value}_${option.label}`,
											value: option.value,
										});
										return accumulator;
									},
									[{ value: "All", label: t("util.util.all") }]
								)}
							/> */}

							<Text>{t("util.util.Date")} : </Text>
							{/*預交日期起、迄*/}
							<RangePicker
								allowClear={false}
								value={[
									s_searchData.startDate && dayjs(s_searchData.startDate),
									s_searchData.endDate && dayjs(s_searchData.endDate),
								]}
								onChange={(dates, dateStrings) => handle_SearchData_Change("time", dateStrings)}
							/>

							<IconExcelDownload
								key="excel"
								className="text-[40px]"
								onClick={() => exportToExcel()}
							/>

							<Button
								type={dateRangeType === "day" ? "primary" : "default"}
								onClick={() => handleDateRangeTypeChange("day")}
							>
								{t("PMS.report.Today")}
							</Button>

							<Button
								type={dateRangeType === "week" ? "primary" : "default"}
								onClick={() => handleDateRangeTypeChange("week")}
							>
								{t("PMS.report.ThisWeek")}
							</Button>

							<Button
								type={dateRangeType === "month" ? "primary" : "default"}
								onClick={() => handleDateRangeTypeChange("month")}
							>
								{t("PMS.report.ThisMonth")}
							</Button>

							<SearchButton
								type="primary"
								key="search"
								onClick={handleClick}
								className="
							bg-sky-500 border-sky-500 text-white
							hover:bg-sky-400 hover:border-sky-400
							focus:bg-sky-500 focus:border-sky-500
							"
							/>

							<Button type="primary" onClick={() => handleExpandedRowKeys()}>
								{is_expanded_AllRow ? <CompressOutlined /> : <ExpandOutlined />}
							</Button>
						</Space>

						{/*表格*/}
						<CustomTable
							rowClassName=""
							bordered={false}
							// className="customHeaderBG"
							className="mainTable"
							columns={tableColumns}
							dataSource={s_TableData.map((item, index) => ({ ...item, key: index + 1 }))}
							expandedRowRender={expandedRowRender} //子表格
							expandedRowKeys={expandedRowKeys} //子表格展開行
							// //把展開行的索引記住，每次展開都檢查是否被展開過
							onExpand={(expanded, record) => {
								if (expanded) {
									setExpandedRowKeys([...expandedRowKeys, record.key]);
								} else {
									setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record.key));
								}
							}}
							scroll={{ x: false, y: tableHeight }}
						/>
					</div>
				</Spin>
			</div>

			{/* <div className="flex absolute bottom-2 justify-between items-center text-[24px] text-[#4B6EA2] p-2 w-[99%] bg-[#F8F8F8] rounded-md shadow-[0px_3px_6px_#dcdcdc] "> */}
			{/* <div className="flex flex-1 justify-end items-center"> */}
			{/* <div className="flex">
					{t("OMS.ap-manage.ap_sumtotal")} : {formatAmount(total_Info.sumtotal)} */}
			{/* 總項金額 */}
			{/* </div> */}

			{/* <div className="flex"> */}
			{/* {t("util.util.tax")} : {formatAmount(total_Info.tax)} */}
			{/* 總項稅額 */}
			{/* </div> */}

			{/* <div className="flex"> */}
			{/* {t("PMS.ap-manage.ap_total")} : {formatAmount(total_Info.total)} */}
			{/* 應付含稅總金額 */}
			{/* </div> */}
			{/* </div> */}
			{/* </div> */}
		</>
	);
};

export default InventoryDetail;
