import { Space, Typography, theme } from "antd";
const { Title } = Typography;

const { useToken } = theme;

export default function PageHeader({ title, className, extra }) {
  const { token } = useToken();

  return (
    <div className={`flex col-auto mb-3 w-full ${className}`}>
      <Title level={3} className="m-0 font-normal" style={{ color: token.colorPageHeader }}>
        {title}
      </Title>
      <Space className="flex-1 justify-end">{extra}</Space>
    </div>
  );
}
