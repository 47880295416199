import { Button, Tooltip, theme } from "antd";
/* eslint-disable import/no-anonymous-default-export */
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { IconExcelDownload } from "@/components/Icon/Action";
import { IconCancel, IconConfirm, IconQuote, IconTransfer } from "@/components/Icon/Modal/Button";
import { IconGuideMap, IconOverview, IconRec } from "@/components/Icon/PMS/index";
import { IconAddQrcode } from "@/components/Icon/WOS";
import {
	AimOutlined,
	CheckOutlined,
	CloseCircleOutlined,
	CloseOutlined,
	DeliveredProcedureOutlined,
	FileExcelOutlined,
	LeftOutlined,
	MenuOutlined,
	MinusOutlined,
	MoneyCollectOutlined,
	MoreOutlined,
	PlusOutlined,
	PrinterOutlined,
	RedoOutlined,
	RightOutlined,
	SaveOutlined,
} from "@ant-design/icons";

// context
import SystemContext from "../Context/SystemContext";

// 回導覽
export const TourButton = () => {
	const { t } = useTranslation();
	let navigate = useNavigate();
	const { c_systemConfig } = useContext(SystemContext);
	const { theme = "pms" } = c_systemConfig;
	return (
		<Button
			type="text"
			icon={<IconGuideMap className="text-2xl" />}
			onClick={() => navigate(`/${theme}`)}
		>
			{t("util.util.header_tour")}
		</Button>
	);
};

// 新建的按鈕
export const CreateButton = (props) => {
	return (
		<Button
			shape="circle"
			className={
				!props.disabled &&
				`
      bg-amber-500 border-amber-500 text-white
      hover:bg-amber-400 hover:border-amber-400
      focus:bg-amber-500 focus:border-amber-500
      `
			}
			icon={<PlusOutlined />}
			{...props}
		/>
	);
};

// 清除搜尋紀錄
export const ClearButton = ({ children, ...props }) => {
	const { t } = useTranslation();
	return (
		<Button type="primary" {...props}>
			{/* 清除搜尋紀錄 */}
			{children ? children : t("util.util.removeSearch")}
		</Button>
	);
};

const SharedButton = ({ children, Icon, className, disabled, ...props }) => {
	const { useToken } = theme;
	const { token } = useToken();

	return (
		<div
			style={{
				width: token.sizeLG,
				height: token.sizeLG,
				borderRadius: token.borderRadiusSM,
			}}
			className={`
        flex justify-center items-center 
        ${disabled ? "cursor-not-allowed" : "cursor-pointer"} 
        ${disabled ? "text-stone-400" : "text-white"}
        ${disabled ? "bg-zinc-200" : className}
      `}
			{...props}
			onClick={disabled ? null : props.onClick}
		>
			<Icon style={{ fontSize: token.sizeMD }} />
		</div>
	);
};
// 設定進階篩選條件
export const DetailButton = ({ children, ...props }) => {
	const { t } = useTranslation();
	return (
		<Button type="primary" icon={<AimOutlined />} {...props}>
			{t("util.util.setAdvanced")}
		</Button>
	);
};
// 匯出Excel
export const ExcelButton = ({ children, ...props }) => {
	const { t } = useTranslation();
	return (
		<Button
			icon={<FileExcelOutlined />}
			className={
				!props.disabled &&
				`
      bg-green-700 border-green-700 text-white
      hover:bg-green-600 hover:border-green-600
      focus:bg-green-700 focus:border-green-700
      `
			}
			{...props}
		>
			{t("util.util.exportExcel")}
		</Button>
	);
};
// Table-查看規格評估
export const RecButton = ({ children, ...props }) => {
	const { t } = useTranslation();
	return (
		<Tooltip title={t("PMS.plan.rec_drawer_title")}>
			<SharedButton Icon={IconRec} className="bg-[#4BB0F3]" {...props} />
		</Tooltip>
	);
};
// Table-變更紀錄
export const TableChangeButton = ({ children, ...props }) => {
	const { t } = useTranslation();
	return (
		<Tooltip title="變更紀錄">
			<SharedButton Icon={DeliveredProcedureOutlined} className="bg-yellow-500" {...props} />
		</Tooltip>
	);
};
export const TableChangeButtonTB = ({ children, ...props }) => {
	const { t } = useTranslation();
	return (
		<Tooltip title="變更紀錄">
			<SharedButton Icon={DeliveredProcedureOutlined} className="bg-yellow-900" {...props} />
		</Tooltip>
	);
};
// Table-查看更多
export const SeeMoreButton = ({ children, ...props }) => {
	const { t } = useTranslation();
	return (
		<Tooltip title={t("util.util.table_more")}>
			<SharedButton Icon={MoreOutlined} className="bg-[#7DBBF8]" {...props} />
		</Tooltip>
	);
};
// Table-列印
export const TablePrintButton = ({ children, ...props }) => {
	const { t } = useTranslation();
	return (
		<Tooltip title={t("util.util.header_print")}>
			<SharedButton Icon={PrinterOutlined} className="bg-[#7DBBF8]" {...props} />
		</Tooltip>
	);
};
// Table-強制結令
export const CloseButton = ({ children, ...props }) => {
	const { t } = useTranslation();
	return (
		<Tooltip title={t("util.util.table_close")}>
			<SharedButton Icon={MinusOutlined} className="bg-[#FF9964]" {...props} />
		</Tooltip>
	);
};
// Table-刪除
export const DeleteButton = ({ children, ...props }) => {
	const { t } = useTranslation();
	return (
		<Tooltip title={t("util.util.delete")}>
			<SharedButton Icon={CloseOutlined} className="bg-[#EF4F4F]" {...props} />
		</Tooltip>
	);
};
// Table-取消刪除
export const CancelDeleteButton = ({ children, ...props }) => {
	return (
		<Tooltip title="取消刪除">
			<SharedButton Icon={RedoOutlined} className="bg-[#7fc541]" {...props} />
		</Tooltip>
	);
};
// Table-編輯
export const EditButton = ({ children, ...props }) => {
	const { t } = useTranslation();
	return (
		<Tooltip title={t("util.util.edit")}>
			<SharedButton Icon={MenuOutlined} className="bg-[#7DB5C6]" {...props} />
		</Tooltip>
	);
};
// Table-金額變更
export const ChangeMoneyButton = ({ children, ...props }) => {
	const { t } = useTranslation();
	return (
		<Tooltip title="金額變更">
			<SharedButton Icon={MoneyCollectOutlined} className="bg-[#EF4F4F]" {...props} />
		</Tooltip>
	);
};
// 採購單Table-廠商確認數量
export const SuppCheckButton = ({ children, ...props }) => {
	const { t } = useTranslation();
	return (
		<Tooltip title={t("PMS.util.confirmqty")}>
			<SharedButton Icon={CheckOutlined} className="bg-[#4EC6AA]" {...props} />
		</Tooltip>
	);
};

// mainPage-列印
export const PrintButton = ({ children, ...props }) => {
	const { t } = useTranslation();
	return (
		<Button type="text" icon={<PrinterOutlined className="text-[#539AEB] text-2xl" />} {...props}>
			{t("util.util.header_print")}
		</Button>
	);
};
// mainPage-儲存
export const SaveButton = ({ children, ...props }) => {
	const { t } = useTranslation();
	return (
		<Button type="text" icon={<SaveOutlined className="text-[#4EC6AA] text-2xl" />} {...props}>
			{t("util.util.header_save")}
		</Button>
	);
};
// mainPage-上一筆
export const PrevDataButton = ({ children, ...props }) => {
	const { t } = useTranslation();
	return (
		<Button
			type="text"
			icon={<LeftOutlined className={!props.disabled && "text-[#8683CC] text-2xl"} />}
			{...props}
		>
			{t("util.util.header_prev")}
		</Button>
	);
};
// mainPage-下一筆
export const NextDataButton = ({ children, ...props }) => {
	const { t } = useTranslation();
	return (
		<Button type="text" {...props} className="text-start">
			{t("util.util.header_next")}
			<RightOutlined className={!props.disabled && "text-[#8683CC] text-2xl"} />
		</Button>
	);
};
// mainPage-變更
export const ChangeButton = ({ children, ...props }) => {
	const { t } = useTranslation();
	return (
		<Button
			type="text"
			icon={<DeliveredProcedureOutlined className="text-2xl text-yellow-500" />}
			{...props}
		>
			{t("util.util.change")}
		</Button>
	);
};
// mainPage-回總表
export const SummaryButton = ({ children, ...props }) => {
	const { t } = useTranslation();
	return (
		<Button type="text" icon={<IconOverview className="text-2xl" />} {...props}>
			{t("util.util.header_return")}
		</Button>
	);
};
// mainPage-取消據此創建
export const CancelCreateButton = ({ children, ...props }) => {
	const { t } = useTranslation();
	return (
		<Button type="text" icon={<CloseCircleOutlined className="text-red-500" />} {...props}>
			{t("util.util.header_cancel_create")}
		</Button>
	);
};

//Modal 取消按鈕
export const CancelButton = ({ children, ...props }) => {
	return <Button className="bg-white  w-20" icon={<IconCancel />} {...props} />;
};

//Modal 確定按鈕
export const ConfirmButton = ({ children, ...props }) => {
	return <Button type="primary" className="w-20" icon={<IconConfirm />} {...props} />;
};

//Modal 確定引用按鈕
export const QuoteButton = ({ children, tooltip = "", ...props }) => {
	return (
		<Tooltip title={tooltip}>
			<Button type="primary" className="w-20" icon={<IconQuote />} {...props} />
		</Tooltip>
	);
};

//Modal 確定拋轉按鈕
export const TransferButton = ({ children, tooltip = "", ...props }) => {
	return (
		<Tooltip title={tooltip}>
			<Button type="primary" className="w-20" icon={<IconTransfer />} {...props} />
		</Tooltip>
	);
};

// mainpage - 進料標籤預印
export const PrintButton_LMS = ({ children, ...props }) => {
	const { t } = useTranslation();
	return (
		<Button type="text" icon={<IconAddQrcode className="text-2xl" />} {...props}>
			{t("PMS.pro.instock-print")} - A4
		</Button>
	);
};

// mainPage-列印A4
export const PrintButton_A4 = ({ children, ...props }) => {
	const { t } = useTranslation();
	return (
		<Button type="text" icon={<PrinterOutlined className="text-2xl" />} {...props}>
			{t("util.util.header_print")} - A4
		</Button>
	);
};

// mainPage-列印中一刀
export const PrintButton_Middle_Cut = ({ children, ...props }) => {
	const { t } = useTranslation();
	return (
		<Button type="text" icon={<PrinterOutlined className="text-2xl" />} {...props}>
			{t("util.util.header_print")} - 中一刀(27.8mm*21.7mm)
		</Button>
	);
};

// mainPage-Excel
export const ExcelButton2 = ({ children, ...props }) => {
	const { t } = useTranslation();
	return (
		<Button type="text" icon={<IconExcelDownload className="text-2xl" />} {...props}>
			Excel
		</Button>
	);
};
