import { pms_apiBasic as apiBasic } from "../../APIBasic";
import { instance } from "../../handlerAPI";

//期間採購彙總表
export const purchaseSummary = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/purchaseSummary`, {
    params: data,
  });
};

//採購明細表
export const getPurchaseDetail = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/purchaseDetail`, {
    params: data,
  });
};

//採購明細表(後端分頁)
export const getPaginatedPurchaseDetail = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPaginatedPurchaseDetail`, {
    params: data,
  });
};

//進貨彙總表
export const apSummary = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/apSummary`, {
    params: data,
  });
};

//進貨明細表
export const apDetail = (data) => {
	return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/apDetail`, {
	  params: data,
	});
  };

  //採購單明細表分頁
export const getPaginatedApDetail = (data) => {
	return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPaginatedApDetail`, {
	  params: data,
	});
  };
  

//銷貨明細表
// export const saleDetail = (data) => {
//   return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/saleDetail`, {
//     params: data,
//   });
// };

//匯出進貨明細表
export const exportApDetail = (data) => {
	return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/exportApDetail`, data);
  };

//匯出採購明細表(後端)excel
export const exportPurchaseDetail = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/exportPurchaseDetail`, data);
};

//匯出銷貨彙總表(後端)excel
export const exportApSummary = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/exportApSummary`, data);
};

//匯出期間訂單彙總表(後端)excel
export const exportPurchaseSummary = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/exportPurchaseSummary`, data);
};
