import React from "react";
import { useTranslation } from "react-i18next";

const TITLE_STYLE = "text-md font-bold flex-center py-1";
const BORDER = "border-0 border-solid";

// const WIDTH = {
//     checkMan: "w-[33.3%]",
//     storeMan: "w-[33.3%]",
//     retrunMan: "w-[33.3%]",
// };

const Footer = () => {
	const { t } = useTranslation();

	const SING_LABEL = [
		{ title: t("IMS.plan-out.opcode_4210") },
		{ title: t("IMS.plan-out.whRemovel") },
		{ title: t("IMS.plan-out.whReception") },
		{ title: t("OMS.out-stock.print-pengirim") },
	];
	return (
		<>
			<section className="flex gap-[20px] w-full">
				{SING_LABEL.map((item, i) => (
					<div key={i} className="flex flex-col items-center flex-1">
						<span className={TITLE_STYLE}>{item.title}</span>
						<span className={`h-10 w-full ${BORDER} border-b`} />
						<span className="text-[12px]">Nama Jelas</span>
					</div>
				))}
			</section>
			<div className="flex flex-center gap-[50px] text-[12px] mt-2">
				<i>Asli & Kuning : Accounting.</i>
				<i>Hijau : Warehouse.</i>
				<i>Merah : Customer.</i>
				<i>Biru : Secunty</i>
			</div>
		</>

	);
};
// //page-break-after: always
// const Footer = () => {
//     const { t } = useTranslation();

//     return (
//         <section className="flex flex-col w-full mb-1">
//             <div className={`flex items-center flex-1 ${BORDER} border-y`}>
//                 <span className={` ${TITLE_STYLE} ${BORDER} ${WIDTH.checkMan} border-l`}>{t("IMS.util.auditors")}</span>
//                 <span className={` ${TITLE_STYLE} ${BORDER} ${WIDTH.storeMan} border-x`}>{t("IMS.util.wh_person")}</span>
//                 <span className={`  ${TITLE_STYLE} ${BORDER} ${WIDTH.retrunMan} border-r`}>{t("IMS.util.pick_person")}</span>
//             </div>
//             <div className={`flex items-center flex-1 ${BORDER} border-b`}>
//                 <span className={`h-10  ${BORDER} ${WIDTH.checkMan} border-l`} />
//                 <span className={`h-10  ${BORDER} ${WIDTH.storeMan} border-x`} />
//                 <span className={`h-10  ${BORDER} ${WIDTH.retrunMan} border-r`} />
//             </div>
//             {/* {SING_LABEL.map((item, i) => (

//             ))} */}
//         </section>
//     );
// };

export default Footer;
