/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { Button, message, Space, Modal, Drawer } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import useAPI from "@/hooks/useAPI";
import { getTxnHd, txn, deleteTxnHd, getTxnTb } from "@/service/apis/WMS/WMS";
import { IconCircle, IconGarbage } from "@/components/Icon/Action";
import { IconPrinter } from "@/components/Icon/WOS";
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import { formatAmount } from "@/util/format";
import ModalContainer from "./ModalContainer";
import dayjs from "dayjs";
import Print4110 from "./Print4110";

const Production = forwardRef((props, ref) => {
  const { c_lightData, set_c_lightData, c_searchData } = props;
  const { t } = useTranslation();
  const Print_ref = useRef(null); // 列印用

  const [s_showModal, set_s_showModal] = useState(false); // 是否開啟編輯庫房入庫的對話框
  const [s_tableData, set_s_tableData] = useState([]);
  // 列印用_取得表身資料
  const [s_printType, set_s_printType] = useState({ width: "19.6cm", height: "12.9cm" });
  const call_getTxnTb = useAPI(getTxnTb);
  //  const call_getTxnTb = useAPI(getTxnTb);
  // // 列印用_抽屜
  // const [s_showLqModal, set_s_showLqModal] = useState(false);
  // 列印用_抽屜的表頭資料
  const [s_printData, set_s_printData] = useState({});
  const [s_tbData, set_s_tbData] = useState([]); // 列印用_抽屜的表身資料


  const call_getTxnHd = useAPI(getTxnHd);
  const call_txn = useAPI(txn);
  const call_deleteTxnHd = useAPI(deleteTxnHd);

  // 後端的資料
  const [s_pageInfo, set_s_pageInfo] = useState({
    currentPage: 0,
    endItemNumber: 0,
    pageItemNumber: 0,
    startItemNumber: 0,
    totalItems: 0,
  });

  // 送給後端的資料
  const [s_searchValue, set_s_searchValue] = useState({
    page: 1,
    pageSize: 200,
  });


  // 列印用_列印props
  const printerProps = {
    ref: Print_ref,
    hd_values: s_printData,
    s_tbData: s_tbData,
    // set_s_showLqModal: set_s_showLqModal,
    s_printType: s_printType
  };

  const tableColumns = [
    {
      title: t("IMS.util.opcode"),
      dataIndex: "opcode",
      align: "center",
      render: (text) => t(`IMS.util.opcode_${text}`),
    },
    {
      title: t("IMS.util.jobN"),
      dataIndex: "jobN",
      align: "center",
    },
    {
      title: t(`IMS.util.preN_${c_searchData.opcode}`),
      dataIndex: "woN",
      align: "center",
    },
    {
      title: t("IMS.util.planqty"),
      dataIndex: "planqty",
      align: "right",
      render: (text) => formatAmount(text),
    },
    {
      title: t("IMS.util.realtqty"),
      dataIndex: "realqty",
      align: "right",
      render: (text) => formatAmount(text),
    },
    {
      title: t("IMS.util.bal"),
      dataIndex: "bal",
      align: "right",
      render: (text) => formatAmount(text),
    },
    {
      title: t("util.util.lifeF"),
      dataIndex: "lifeF",
      align: "center",
      render: (text) => t(`IMS.util.lifeF_${text}`),
      onCell: (rowData) => ({ className: `lifeF-${rowData.lifeF}` }),
    },
    {
      title: t("util.util.updtT"),
      dataIndex: "lifeFT",
      align: "center",
      render: (text) => dayjs(text).format("MM-DD HH:mm"),
    },
    {
      title: t("util.util.action"),
      dataIndex: "render",
      align: "center",
      render: (_, rowData) => (
        <Space>
          <IconPrinter
            // 列印用_設置rowData對應資料列印觸發鈕
            onClick={(e) => {
              set_s_printData(rowData);
              // set_s_showLqModal(true);
              printer();
            }}
          />
          <IconCircle disabled={rowData.lifeF !== "2"} onClick={(e) => recycleTxn(e, rowData)} />
          <IconGarbage disabled={rowData.lifeF !== "0"} onClick={(e) => deleteTxn(e, rowData)} />
        </Space>
      ),
    },
  ];

  // 列印用_設置列印尺寸選擇
  const printer = (data) => {
    Modal.info({
      // title: "請選擇列印尺寸",
      title: t("util.util.placeholder_select") + t("APS.aps-manage.print_size"),
      content: (
        <div className="flex justify-center gap-2">
          <Button
            className="flex-1 h-10"
            // disabled
            type="primary"
            onClick={() => {
              set_s_printType({ width: "19.6cm", height: "12.9cm" });

              Modal.destroyAll();

              setTimeout(() => {
                Print_ref.current.Print_helf();
              }, 1000);
            }}
          >
            A5
          </Button>

          <Button
            className="flex-1 h-10"
            // disabled
            type="primary"
            onClick={() => {
              set_s_printType({ width: "19.6cm", height: "25.8cm" });

              Modal.destroyAll();

              setTimeout(() => {
                Print_ref.current.Print_helf();
              }, 1000);
            }}
          >
            A4
          </Button>
        </div>
      ),
      okText: <IconConfirm />, // 確定
    });
  };


  useImperativeHandle(ref, () => ({
    Create: () => set_s_showModal(true), //開啟新增Modal
    Call_hd: () => call_getTxnHd.request({ ...c_searchData, ...s_searchValue }),
  }));

  const recycleTxn = (e, rowData) => {
    Modal.confirm({
      title: t("IMS.util.txn_confirm"),
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: t("IMS.util.txn_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_txn.request(rowData),
      cancelText: t("util.util.cancel"),
    });

    e.stopPropagation(); // 這裡不要開啟表身
  };

  const deleteTxn = (e, rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteTxnHd.request(rowData),
      cancelText: t("util.util.cancel"),
    });

    e.stopPropagation(); // 這裡不要開啟表身
  };

  // 列印用_需要新的列印資料時，從後端獲取相應的表身數據，確保列印使用最新的資料
  useEffect(() => {
    // 檢查s_printData中是否有資料
    if (Object.keys(s_printData).length > 0) {
      // 發送請求，s_printData作為參數
      call_getTxnTb.request(s_printData);
    }
    // console.log(s_printData)
  }, [s_printData]);

  // 列印用_根據表身的資料狀態執行
  useEffect(() => {
    // 從call_getTxnTb提取data、status和msg。status => load, err, suc
    const { data, status, msg } = call_getTxnTb;
    if (status === "suc") {
      // 調用set_s_tbData(data)將數據保存到狀態s_tbData中
      set_s_tbData(data);
      // console.log(s_tbData)
    } else if (status === "err") {
      set_s_tbData([]);
    }
  }, [call_getTxnTb.status]);

  // c_searchData 變更時改變 page
  useEffect(() => {
    set_c_lightData({});
    set_s_searchValue((prev) => ({ ...prev, page: 1 }));
  }, [c_searchData]);

  useEffect(() => {
    call_getTxnHd.request({ ...c_searchData, ...s_searchValue });
  }, [s_searchValue]);

  useEffect(() => {
    if (call_getTxnHd.status === "suc") {
      const { tableData, pageInfo } = call_getTxnHd.data;
      const newTableData = tableData.map((x, i) => ({ ...x, key: x.planN, index: i }));

      set_s_tableData(newTableData);
      set_s_pageInfo(pageInfo);

      // 更新 c_lightData
      if (c_lightData.planN && !newTableData.some((x) => x.planN === c_lightData.planN)) {
        set_c_lightData({});
      } else {
        const thisData = newTableData.find((x) => x.planN === c_lightData.planN);
        set_c_lightData(thisData ? { ...thisData, key: thisData.planN } : {});
      }
      message.success(call_getTxnHd.msg);
    } else if (call_getTxnHd.status === "err") {
      set_s_tableData([]);
      message.error(call_getTxnHd.msg);
    }
  }, [call_getTxnHd.status]);

  useEffect(() => {
    if (call_txn.status === "suc") {
      message.success(call_txn.msg);
      call_getTxnHd.request({ ...c_searchData, ...s_searchValue });
    } else if (call_txn.status === "err") {
      message.error(call_txn.msg);
    }
  }, [call_txn.status]);

  useEffect(() => {
    if (call_deleteTxnHd.status === "suc") {
      message.success(call_deleteTxnHd.msg);
      call_getTxnHd.request({ ...c_searchData, ...s_searchValue });
    } else if (call_deleteTxnHd.status === "err") {
      message.error(call_deleteTxnHd.msg);
    }
  }, [call_deleteTxnHd.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        loading={call_getTxnHd.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        onRow={(record) => ({
          onClick: () =>
            record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
        })}
        pagination={{
          total: s_pageInfo.totalItems,
          current: s_searchValue.page,
          pageSize: s_searchValue.pageSize,
          onChange: (current, size) =>
            set_s_searchValue((prev) => ({
              ...prev,
              page: prev.pageSize !== size ? 1 : current,
              pageSize: size,
            })),
        }}
      />

      <CustomModal
        title={
          Object.keys(c_lightData).length > 0
            ? `${t("util.util.edit")}${t(`IMS.util.opcode_${c_searchData.opcode}`)}`
            : `${t("util.util.add")}${t(`IMS.util.opcode_${c_searchData.opcode}`)}`
        }
        width="60%"
        open={s_showModal}
        onCancel={() => set_s_showModal(false)}
        afterClose={() => {
          set_c_lightData({});
          call_getTxnHd.request({ ...c_searchData, ...s_searchValue });
        }}
      >
        <ModalContainer
          opcode={c_searchData.opcode}
          set_s_showModal={set_s_showModal}
          s_editData={c_lightData}
        />
      </CustomModal>
      {/* 列印抽屜 */}
      <Drawer
        placement="left"
        // className="hidden"
        closable={false}
        width={0}
        height={1000}
        // onClose={() => set_s_showLqModal(false)}
        // open={s_showLqModal}
        open={true}
        mask={false}
      >
        <Print4110  {...printerProps} />
      </Drawer>

    </>
  );
});

export default Production;
