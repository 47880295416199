import apiBasic from "../../APIBasic";
import { instance } from "../../handlerAPI";

export const getSDS003 = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getSDS003`, {
    params: _params,
  });
};

export const printWoN = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/printWoN`, {
    params: _params,
  });
};

export const getZPLimg = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/zpl`, data);
};

export const reprint = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/reprint`, data);
};

export const print = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/print`, data);
};

export const getModelHistory = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getModelHistory`, {
    params: _params,
  });
};

export const getModelHistoryExcel = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getModelHistoryExcel`, {
    params: _params,
  });
};

export const getModelPdt = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getModelPdt`, { params: _params });
};

export const addModelPdt = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addModelPdt`, data);
};

export const updateModelPdt = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateModelPdt`, data);
};

export const deleteModelPdt = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteModelPdt`, data);
};

export const getModelHd = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getModelHd`, {
    params: _params,
  });
};

export const getModelTb = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getModelTb`, { params: _params });
};

export const printHistory = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/printHistory`, { params: _params });
};

export const addModelHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addModelHd`, data);
};

export const updateModelHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateModelHd`, data);
};

export const deleteModelHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteModelHd`, data);
};

export const getPrinter = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPrinter`, { params: _params });
};

export const addPrinter = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addPrinter`, data);
};

export const updatePrinter = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updatePrinter`, data);
};

export const deletePrinter = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deletePrinter`, data);
};

export const getRibbon = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getRibbon`, { params: _params });
};

export const addRibbon = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addRibbon`, data);
};

export const updateRibbon = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateRibbon`, data);
};

export const deleteRibbon = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteRibbon`, data);
};

export const getLabel = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getLabel`, { params: _params });
};

export const addLabel = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addLabel`, data);
};

export const updateLabel = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateLabel`, data);
};

export const deleteLabel = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteLabel`, data);
};

export const getDaycodeHd = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getDaycodeHd`, { params: _params });
};

export const addDaycodeHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addDaycodeHd`, data);
};

export const updateDaycodeHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateDaycodeHd`, data);
};

export const deleteDaycodeHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteDaycodeHd`, data);
};

export const getDaycodeTb = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getDaycodeTb`, { params: _params });
};

export const addDaycodeTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addDaycodeTb`, data);
};

export const updateDaycodeTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateDaycodeTb`, data);
};

export const deleteDaycodeTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteDaycodeTb`, data);
};

export const getPrintModel = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPrintModel`, {
    params: _params,
  });
};
