import React from "react";
import { useTranslation } from "react-i18next";

const TITLE_STYLE = "text-[12px] font-bold flex-center border-0 border-solid border-y py-1";
const BORDER = "border-0 border-solid";

const WIDTH = {
  itemno: "w-[5%]",
  PN: "w-[18%]",
  dealPN: "w-[28%]",
  pdtNM: "w-[7%]",
  pdtspec: "w-[14%]",
  indqty: "w-[14%]",
  boxnum: "w-[14%]",
  // barcode: "w-[28%]",
};

const TbContainer = ({ hd_values, s_tbData }) => {
  const { t } = useTranslation();

  return (
    <section className="flex-1 flex flex-col">
      <div className="border-0 border-solid w-full flex mb-2">
        {/* 項序 */}
        <span className={`${WIDTH.itemno} border-x  ${TITLE_STYLE}`}>序</span>
        <span className={`${WIDTH.PN} ${TITLE_STYLE}`}>料號</span>
        <span className={`${WIDTH.dealPN} border-x ${TITLE_STYLE}`}>描述</span>
        <span className={`${WIDTH.pdtNM} border-r ${TITLE_STYLE}`}>單位</span>
        <span className={`${WIDTH.pdtspec} border-r ${TITLE_STYLE}`}>計畫數量</span>
        <span className={`${WIDTH.indqty} border-r ${TITLE_STYLE}`}>實際退料</span>
        <span className={`${WIDTH.boxnum} border-r ${TITLE_STYLE}`}>備註</span>
      </div>
      <section className={`flex flex-col ${BORDER} border-t`}>
        {s_tbData?.map((item, i) => (
          <div key={i} className={`flex items-center w-full text-[12px] ${BORDER} border-b`}>
            <span className={`h-7 ${WIDTH.itemno} flex-center ${BORDER} border-x`}>
              {item.itemno}
            </span>
            <span className={`${WIDTH.PN} flex-center `}>{item.PN}</span>
            <span className={`h-7 ${WIDTH.dealPN} flex-center ${BORDER} border-x`}>
              {item.dealPN}
            </span>
            <span className={`h-7 flex flex-center  ${WIDTH.pdtNM} ${BORDER} border-r`}>
              {item.itemno}
            </span>
            <span
              className={`h-7 pr-1 flex items-center justify-end ${WIDTH.pdtspec} ${BORDER} border-r`}
            >
              {item.pdtspec}
            </span>
            <span
              className={`h-7 pr-1 flex items-center justify-end ${WIDTH.indqty} ${BORDER} border-r`}
            >
              {item.indqty}
            </span>
            <span className={`h-7 flex-center ${WIDTH.boxnum} ${BORDER} border-r`}>
              {item.note}
            </span>
          </div>
        ))}
      </section>
    </section>
  );
};

export default TbContainer;
