import { oms_apiBasic as apiBasic } from "../../APIBasic";
import { instance } from "../../handlerAPI";

// 取得訂單出貨資料
export const getShipped = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getShipped`, {
    params: data,
  });
};
// 取得變更管理
export const getRecordHD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getRecordHD`, {
    params: data,
  });
};

export const getRecordTB = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getRecordTB`, {
    params: data,
  });
};

// 新建表頭資料
export const addOrdHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addOrdHD`, data);
};

// 變更管理
export const changeOrdHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/changeOrdHD`, data);
};
// 變更管理
export const changeOrdTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/changeOrdTB`, data);
};
// 取得表頭資料
export const getOrdHdAd = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getOrdHdAd`, {
    params: data,
  });
};
// 取得表頭資料
export const getOrdHD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getOrdHD`, {
    params: data,
  });
};
// 取得表頭資料
export const getPaginateOrdHD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPaginateOrdHD`, {
    params: data,
  });
};
// 更新表頭資料
export const updateOrdHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateOrdHD`, data);
};
// 刪除表頭資料
export const deleteOrdHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteOrdHD`, data);
};
// ==================================================================================
// 拋轉發貨單
export const ordToOutstock = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/ordToOutstock`, data);
};
// ==================================================================================
// 取得入庫單(表身)
export const getStockTb = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getStockTbOrd`, {
    params: data,
  });
};

//------------------------ 取得條款註記、預付作業、加扣款項 ----------------------------------------
export const getOrdADD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getOrdADD`, {
    params: data,
  });
};
//------------------------ 新增條款註記、預付作業、加扣款項 ----------------------------------------
export const addOrdADD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addOrdADD`, data);
};
//------------------------ 更新條款註記、預付作業、加扣款項 ----------------------------------------
export const updateOrdADD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateOrdADD`, data);
};
//------------------------ 更新條款註記、預付作業、加扣款項 ----------------------------------------
export const deleteOrdADD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteOrdADD`, data);
};

//-----------------------------------------表身資料----------------------------------------
// 新建表身資料
export const addOrdTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addOrdTB`, data);
};
// 取得表身資料
export const getOrdTB = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getOrdTB`, {
    params: data,
  });
};
// 更新表頭資料
export const updateOrdTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateOrdTB`, data);
};
// 刪除表頭資料
export const deleteOrdTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteOrdTB`, data);
};
//-----------------------------------------收貨地址、發票地址----------------------------------------
export const getContact = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/selector/getContact`, {
    params: data,
  });
};

//-----------------------------------------取得條款註記、預付作業、加扣款項下拉選單----------------------------------------
export const getAdd = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/selector/getAdd`, { params: data });
};

//-----------------------------------------拋轉----------------------------------------
export const OrdToInstock = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/OrdToInstock`, data);
};

// -----------------------------------------退出處置----------------------------------------
export const returnStock = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/returnStock`, data);
};

//-----------------------------------------取得繼承料號----------------------------------------
export const getHeirPN = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/selector/getHeirPN`, {
    params: data,
  });
};

// -----------------------------------------取得訂單明細銷貨單價----------------------------------------

export const getUnitPriceOrd = (data) => {
	return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getUnitPriceOrd`, {
	  params: data,
	});
  };