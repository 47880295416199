import { Divider } from "antd";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { useReactToPrint } from "react-to-print";

import Footer from "./Footer";
import Header from "./Header";
import TbContainer from "./TbContainer";

const Print4120 = forwardRef((props, Print_ref) => {
  const { hd_values, s_printType, s_tbData, set_s_showLqModal } = props;
  const printRef = useRef(null);
  console.log("s_tbData = ", s_tbData);

  useImperativeHandle(Print_ref, () => ({
    Print_helf: (type) => {
      Print_helf();
    },
  }));

  const Print_helf = useReactToPrint({
    // onBeforePrint: () => {
    //   console.log("BeforePrint_Cut",trendData);
    // },
    content: () => {
      return printRef.current;
    },
    bodyClass: "print-style",

    pageStyle: () => `
    body{
      -webkit-print-color-adjust: exact; 
      overflow: visible;

      
    }
    @page {
      size: ${s_printType.width} ${s_printType.height} portrait;

    }
  `,
  });
  // size:A4 portrait;
  // size: 21.9cm 13.9cm;
  // 一頁最多有幾筆明細(不含價格趨勢)
  const page_length = s_printType.height === "12.9cm" ? 8 : 19;
  const pages = Math.ceil(s_tbData.length / page_length);

  const getPages = (array, pageSize) => {
    console.log("array = ", array);
    return array.reduce((prev, curr, index) => {
      // 計算當前明細屬於第幾頁
      const page = Math.floor(index / pageSize) + 1;
      // 計算當前頁的資料陣列
      const data = prev[page - 1] ? prev?.[page - 1] : [];
      // 將當前元素加入當前頁的資料陣列
      // curr.itemno = index + 1;
      prev[page - 1] = [...data, curr];
      // prev[page - 1] = { currentPage: page, data: [...data, curr] };
      return prev;
    }, []);
  };
  const renderdata = getPages(s_tbData, page_length);
  console.log("renderdata = ", renderdata);
  return (
    <div
      id="content1"
      ref={printRef}
      // style={{ minHeight: `${ renderdata.length * 100 } vh` }} // 不用 Tailwind 是因為 Tailwind 不支持變數的寫法
      style={{
        // height: s_printType.height,
        // width:s_printType.width,
        // width:s_printType.width,
        // height: s_printType.height,
        // height: `${renderdata.length * 12.9} vh`,
        margin: "9mm 0mm 0mm 20mm",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "space-between",
        // alignItems: "center",
        gap: "1cm",
      }}
    >
      {renderdata?.map((item, index) => (
        <div
          style={{
            // border: "1px solid #000", // 勿動
            //  backgroundColor: "red",
            //  height: "100%",
            //   width: "100%",
            width: s_printType.width,
            height: s_printType.height,
            display: "flex",
            flexDirection: "column",

            pageBreakAfter: "always",
            //  justifyContent: "start",
            //  alignItems: "center",
          }}
        >
          <Header hd_values={hd_values} />
          <TbContainer hd_values={hd_values} s_tbData={item} />
          <Footer />
        </div>
      ))}
    </div>
  );
});


export default Print4120;
