import React, { useState, useRef } from "react";
import { Button, DatePicker, Drawer } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import PageHeader from "@/components/PageHeader/index";
import { useTranslation } from "react-i18next";
import { AddButton } from "@/components/PDS/Buttons";
import Production from "./Production";
import In from "./In";

const OPCODE_LABELS = [
	// { key: "4110", label: "IMS.util.opcode_4110" }, //生產入庫
	{ key: "4120", label: "IMS.util.opcode_4120" }, //採購入庫
	// { key: "4130", label: "IMS.util.opcode_4130" }, //銷售退回
	// { key: "4141", label: "IMS.util.opcode_4141" }, //庫房調入
	// { key: "4142", label: "IMS.util.opcode_4142" }, //庫房借入
	// { key: "4143", label: "IMS.util.opcode_4143" }, //借出歸還
	{ key: "4160", label: "生產回庫" }, //生產退料
	{ key: "4010", label: "IMS.util.opcode_4010" }, //開帳盤點
	{ key: "4020", label: "IMS.util.opcode_4020" }, //週期盤點
	// { key: "4030", label: "IMS.util.opcode_4030" }, //專案盤點
];

const DATE_FORMAT = "YYYY-MM-DD";

const PlanInManage = () => {
	const { t } = useTranslation();
	const productionRef = useRef();
	const inRef = useRef();

	// 要搜尋的資料
	const [c_searchData, set_c_searchData] = useState({
		opcode: "4120",
		startDate: dayjs().startOf("month").format(DATE_FORMAT),
		endDate: dayjs().endOf("month").format(DATE_FORMAT),
		lifeF: "",
	});

	const opcodeData = OPCODE_LABELS.map((x) => ({
		key: x.key,
		label: t(x.label),
		// disabled: x.key === "4110",
		children:
			x.key === "4110" ? (
				<Production ref={productionRef} c_searchData={c_searchData} />
			) : (
				<In ref={inRef} c_searchData={c_searchData} />
			),
	}));

	// 更改搜尋項目
	const handleChange = (type, value) => {
		set_c_searchData((prev) => {
			if (type === "time") {
				prev.startDate = dayjs(value[0]).format(DATE_FORMAT);
				prev.endDate = dayjs(value[1]).format(DATE_FORMAT);
			} else {
				prev[type] = value;
			}
			return { ...prev };
		});
	};
	// 新增
	const handleClick = () => {
		switch (c_searchData.opcode) {
			case "4110":
				productionRef.current.Create();
				break;
			default:
				inRef.current.Create();
				break;
		}
	};

	const searchItem = [
		{ value: "", label: t("IMS.util.lifeF_A") }, //"全部"
		{ value: "0", label: t("IMS.util.lifeF_0") },//"已創建"
		{ value: "1", label: t("IMS.util.lifeF_1") },//"執行中"
		{ value: "2", label: t("IMS.util.lifeF_2") },//"已提交"
		{ value: "T", label: t("IMS.util.lifeF_T") },//"已異動"
	].map((option) => (
		<Button
			key={option.value}
			type={c_searchData.lifeF === option.value ? "primary" : "default"}
			onClick={() => set_c_searchData((prev) => ({ ...prev, lifeF: option.value }))}
		>
			{option.label}
		</Button>
	));

	return (
		<>
			<PageHeader
				title="14-H 資材入庫作業管理"
				extra={[
					...searchItem,
					<DatePicker.RangePicker
						key="time"
						allowClear={false}
						value={[dayjs(c_searchData.startDate), dayjs(c_searchData.endDate)]}
						format={DATE_FORMAT}
						onChange={(dates) => handleChange("time", dates)}
					/>,
					!OPCODE_LABELS[c_searchData.opcode] && (
						// <Button
						//   key="add"
						//   onClick={handleClick}
						//   type="primary"
						//   className="styleButton"
						//   icon={<PlusOutlined />}
						// >
						//   {t(`util.util.add`)}
						// </Button>
						<AddButton type="primary" key="add" onClick={handleClick} />
					),
				]}
			/>
			<CustomTabs
				onChange={(activeKey) => handleChange("opcode", activeKey)}
				activeKey={c_searchData.opcode}
				items={opcodeData}
			/>
		</>
	);
};

export default PlanInManage;
