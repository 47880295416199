import React from "react";
import { useTranslation } from "react-i18next";

const TITLE_STYLE = "text-[12px] font-bold flex-center border-0 border-solid";
const BORDER = "border-0 border-solid";

const WIDTH = {
  itemno: "w-[6%]",
  PN: "w-[18%]",
  dealPN: "w-[18%]",
  pdtNM: "w-[28%]",
  // pdtspec: "w-[10%]",
  indqty: "w-[17%]",
  boxnum: "w-[13%]",
  // barcode: "w-[28%]",
};

const TbContainer = ({ hd_values, s_tbData }) => {
  const { t } = useTranslation();

  return (
    <section className="flex-1 flex flex-col">
      <div className="border-0 border-t border-b border-solid w-full flex ">
        {/* 項序 */}
        <span className={`${WIDTH.itemno} border-x ${TITLE_STYLE}`}>{t("util.util.itemno")}</span>
        <span className={`${WIDTH.PN}  ${TITLE_STYLE}`}>EPN</span>
        <span className={`${WIDTH.dealPN} border-l ${TITLE_STYLE}`}>{t("IMS.plan-in.opcode_4120_spn")}</span>
        <span className={`${WIDTH.pdtNM} border-l ${TITLE_STYLE}`}>{t("util.util.pdtNM")}</span>
        {/* <span className={`${WIDTH.pdtspec} border-l ${TITLE_STYLE}`}>{t("util.util.pdtspec")}</span> */}
        <span className={`${WIDTH.indqty} border-l ${TITLE_STYLE}`}>
          {/* 發貨數量 */}
          {t("IMS.plan-in.inboundQty")}
        </span>
        <span className={`${WIDTH.boxnum} border-x ${TITLE_STYLE}`}>
          {t("OMS.out-stock.boxnum")}
        </span>
      </div>
      <section className={`flex flex-col ${BORDER} border-b`}>
        {s_tbData?.map((item, i) => (
          <div key={i} className={`flex items-center w-full text-[12px] ${BORDER}`}>
            <span className={`h-6 ${WIDTH.itemno} flex-center ${BORDER} border-x`}>
              {item.itemno}
            </span>
            <span className={`${WIDTH.PN} flex-center  ${BORDER}`}>{item.PN}</span>
            <span className={`h-6 ${WIDTH.dealPN} flex-center ${BORDER} border-l`}>{item.dealPN}</span>
            <span className={`h-6 pl-[3px] flex items-center ${WIDTH.pdtNM} ${BORDER} border-l`}>
              {item.pdtNM}
            </span>
            <span className={`h-6 pl-3 flex items-center ${WIDTH.indqty} ${BORDER} border-l`}>
              {item.planqty}
            </span>
            <span className={`h-6 flex-center ${WIDTH.boxnum} ${BORDER} border-x`}>
              {item.boxnum}
            </span>
          </div>
        ))}
      </section>
    </section>
  );
};

export default TbContainer;
