const domain = {
  conn: window.location.protocol.split(":")[0],
  host: window.location.hostname,
  url: "",
  // 部屬用
  outPort: "",
  // 本地用
  pdsPort: ":5487",
  pmsPort: ":4485",
  omsPort: ":4486",
  imsPort: ":5487",
  bpmPort: ":8989",
};

// 依照domain判斷api請求標頭
switch (domain.host) {
  // case '172.16.3.30':
  //   // 內網
  //   domain.url = `${domain.host}${domain.inPort}`
  //   break;
  default:
    // 外網
    domain.url = `${domain.host}${domain.outPort}`;
}

const isLocal = domain.host === "localhost" || domain.url === "127.0.0.1";

const apiBasic = {
  conn: domain.conn,
  url: isLocal ? domain.host + domain.pdsPort : `${domain.url}/pds`,
};

export const ims_apiBasic = {
  conn: domain.conn,
  url: isLocal ? domain.host + domain.imsPort : `${domain.url}/ims`,
};

export const pms_apiBasic = {
  conn: domain.conn,
  url: isLocal ? domain.host + domain.pmsPort : `${domain.url}/pms`,
};

export const oms_apiBasic = {
  conn: domain.conn,
  url: isLocal ? domain.host + domain.omsPort : `${domain.url}/oms`,
};

export const bpm_apiBasic = {
  conn: domain.conn,
  url: isLocal ? domain.host + domain.bpmPort : `${domain.url}/bpm`,
};

export default apiBasic;
