import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Barcode from "react-jsbarcode";

import { IconYUJULogo } from "@/components/Icon/YUJU";
// 引用打API HOOKS
import useAPI from "@/hooks/useAPI";
// 飲用後端API資料
import { getOutstockHD } from "@/service/apis/OMS/Outstock";

const TITLE_STYLE = "flex text-sm justify-between font-bold";
const VALUE_STYLE = "text-sm font-semibold pl-2";

// 將表身資料props傳進來
const Header = ({ hd_values, pages, currentPage }) => {
  const { t } = useTranslation();

  // 表頭 => hd_values
  // 表身 => s_tbData

  // 設置常數管理與getOutstockHD API調用的交互，用來發起請求
  const call_getOutstockHD = useAPI(getOutstockHD);

  // 為表頭資料設置狀態
  const [s_hdData, set_s_hdData] = useState([]);

  // 透過props傳遞的表身資料判斷來觸發向後端請求資料
  useEffect(() => {
    if (Object.keys(hd_values).length > 0) {
      call_getOutstockHD.request({ N: hd_values.woN });
    }
  }, []);

  // 透過API的狀態來觸發判斷，將取得的資料放入s_hdData
  useEffect(() => {
    // status => load, err, suc
    const { data, status, msg } = call_getOutstockHD;
    if (status === "suc") {
      set_s_hdData(data);
    } else if (status === "err") {
      set_s_hdData([]);
    }
  }, [call_getOutstockHD.status]);

  console.log(s_hdData);
  console.log(hd_values);

  const jobNFormatted = hd_values?.jobN ? hd_values.jobN.substring(2).replace("-", "") : "";
  const WIDTH = ["w-[15%]", "w-[35%]"];

  return (
    <section className="flex flex-col w-full">
      <section className="flex justify-between ">
        <div className="text-m font-bold">
          <IconYUJULogo className="text-2xl mr-1" />
          <span className="text-m font-bold ml-2">SAT</span>
        </div>
        <div>
          <span className="text-sm font-bold">{`page ${currentPage}/${pages}`}</span>
        </div>
      </section>
      <section>
        <div className="relative flex flex-center mt-2 mb-5">
          <span className="absolute text-[22px] font-bold tracking-wide flex flex-center">
            {t("IMS.plan-out.print_opcode_4260")}
          </span>
          <span className="ml-auto">
            <Barcode
              value={`${hd_values.opcode}${hd_values.jobN}`}
              options={{
                displayValue: false,
                width: 1,
                height: 20,
                margin: 0,
              }}
            />
          </span>
        </div>
      </section>
      <section className="flex flex-col w-full">
        <section className="flex mb-1">
          {/* 第一行 */}
          <div className="flex w-full">
            {/* 領料單號 */}
            <span className={`${WIDTH[0]} ${TITLE_STYLE}`}>
              <span>{t("IMS.util.pickN")}</span>
              <span>:</span>
            </span>
            <span
              className={`${WIDTH[1]} ${VALUE_STYLE}`}
            >{`${hd_values.opcode}-${hd_values?.jobN}`}</span>

            {/* <span className={`${WIDTH[3]} ${VALUE_STYLE} flex align-center`}>
              <Barcode
                value={`${hd_values?.opcode}-${jobNFormatted}`}
                options={{
                  displayValue: false,
                  width: 1,
                  height: 15,
                  margin: 0
                }}
              /></span> */}
          </div>
        </section>
        <section className="flex mb-1">
          {/* 第二行 */}
          <div className="flex w-full">
            {/* 工單號 */}
            <span className={`${WIDTH[0]} ${TITLE_STYLE} `}>
              <span>{t("util.util.woN")}</span>
              <span>:</span>
            </span>
            <span className={`${WIDTH[1]} ${VALUE_STYLE}`}>{hd_values?.woN}</span>
            {/* 倉庫 */}
            <span className={`${WIDTH[0]} ${TITLE_STYLE}`}>
              <span>{t("util.util.whID")}</span>
              <span>:</span>
            </span>
            <span className={`${WIDTH[1]} ${VALUE_STYLE}`}>{hd_values?.whID}</span>
          </div>
        </section>
        <section className="flex mb-1">
          {/* 第三行 */}
          <div className="flex w-full">
            {/* 領料日期 */}
            <span className={`${WIDTH[0]} ${TITLE_STYLE} `}>
              <span>{t("IMS.util.pickDate")}</span>
              <span>:</span>
            </span>
            <span className={`${WIDTH[1]} ${VALUE_STYLE}`}>{s_hdData?.lifeFT}</span>
            <span className={`${WIDTH[0]} ${TITLE_STYLE} border-0 border-solid border-y border-l`}>
              {/* <span>註記</span>
              <span>:</span> */}
            </span>
            <span className={`${WIDTH[1]} ${VALUE_STYLE} border-0 border-solid border-y border-r`}>
              {s_hdData?.note}
            </span>
          </div>
        </section>
      </section>
    </section>
  );
};

export default Header;
