/**
 * 格式化數字
 * @param {number|string} num 要格式化的數字
 * @param {string} locale 語言代碼
 * @param {object} options 格式選項
 * @returns {string} 格式化後的數字字符串
 */
export function formatNumber(num, locale, options = {}) {
  const tmp = typeof num === "string" ? parseFloat(num) : num;
  const formattedNumber = new Intl.NumberFormat(locale, options).format(tmp);

  // 將不間斷空格（U+202F）替換為普通空格（U+0020）
  return formattedNumber.replace(/\u202F/g, " ");
}

/**
 * 將數字添加逗號分隔
 * @param {number|string} x 要處理的數字
 * @returns {string|undefined} 處理後的數字字符串，如果輸入無效則返回 undefined
 */
export function numberWithCommas(x) {
  // 測試是否為一個可變成數字的字串
  const tryNumber = /^\d+(\.\d+)?$/.test(x);
  if (typeof x === "number" || (typeof x === "string" && tryNumber)) {
    return String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return undefined;
  }
}

// export const formatAmount = (amount, num = 2) => {
//   if (amount === null || amount === undefined) return "0";

//   amount = parseFloat(amount);
//   if (isNaN(amount)) return "0";

//   return amount % 1 === 0
//     ? // 如果amount是整數，不需要保留小數點
//       amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
//     : // 否則，保留小數點至第二位
//       amount.toFixed(num).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// };

export const formatAmount = (amount, num = 2) => {
	if (amount === null || amount === undefined) return "0";
  
	amount = parseFloat(amount);
	if (isNaN(amount)) return "0";
  
	// 如果數字小於1，直接返回保留小數點至第二位但不加千分位逗號的數字
	if (Math.abs(amount) < 1) {
	  return amount.toFixed(num);
	}
  
	const formattedAmount = amount % 1 === 0
	  ? // 如果amount是整數，不需要保留小數點
		amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	  : // 否則，保留小數點至第二位
		amount.toFixed(num).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  
	return formattedAmount;
  };

  // 顯示小數點第三位，整數也要顯示.000
  export const formatAmountNumThree = (amount, num = 2) => {
	if (amount === null || amount === undefined) return "0";
  
	amount = parseFloat(amount);
	if (isNaN(amount)) return "0";

	// 使用toFixed來確保顯示指定位數
	const formattedAmount = amount.toFixed(num).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  
	return formattedAmount;
};

  
export const multipleIDtoName = (arr, text, id, name) => {
  if (!Array.isArray(text)) text = [text];

  return arr
    ?.filter((x) => new Set(text).has(x[id]))
    .map((x) => (name ? `${x[id]}_${x[name]}` : x[id]))
    .join(`,\n`);
};

export const multipleIDtoNameWithFullWidth = (arr, text, id, name) => {
  return arr
    ?.filter((x) => new Set(text).has(x[id]))
    .map((x) =>
      name ? (
        <div className="w-full">
          {x[id]}_{x[name]}
        </div>
      ) : (
        <div className="w-full">{x[id]}</div>
      )
    );
};

export const formatAmountWithCurrency = (amount, currency = "IDR") => {
  if (amount === null || amount === undefined) return "0";

  amount = parseFloat(amount);
  if (isNaN(amount)) return "0";

  // 如果货币是IDR，则四舍五入到整数
  if (currency === "IDR") {
    amount = Math.round(amount);
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // 对非IDR货币，四舍五入到小数点后三位
  amount = amount.toFixed(4);
  // 去除尾随的0
  amount = amount.replace(/(\.\d*?)0+$/, "$1");

  // 如果结果以小数点结尾，则去除小数点
  if (amount.endsWith(".")) {
    amount = amount.slice(0, -1);
  }
  // 去除小数点后不必要的0
  // if (amount.indexOf(".00") >= 0) {
  //   amount = amount.substring(0, amount.indexOf("."));
  // } else if (amount.endsWith("0")) {
  //   amount = amount.substring(0, amount.length - 1);
  // }

    // 分離整數與小數
	const [integerPart, decimalPart] = amount.split(".");

	// 僅對整數添加逗號
	const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  
	// 返回格式化的數值，確保小數點後不加逗號
	if (decimalPart !== undefined) {
	  return `${formattedIntegerPart}.${decimalPart}`;
	} else {
	  return formattedIntegerPart;
	}
//   return amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

//將IDR以外幣別的金額轉為IDR
export const calculateAmount = (amount, currency, exchangeRate) => {
  // 如果货币是 IDR，则直接返回金额
  if (currency === "IDR") {
    return Math.round(amount);
  }
  // 对于其他货币，乘以汇率并四舍五入
  return Math.round(amount * exchangeRate);
};
