import React, { useState, useEffect } from "react";
import { Form, Row, Col, Select, Input, Button, Divider, message, Space, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import useAPI from "@/hooks/useAPI";
import { getAllWhID, updateTxnTb } from "@/service/apis/WMS/WMS";

const ModalContainer = ({ c_lightData, s_editData, set_s_showModal }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const call_getAllWhID = useAPI(getAllWhID);
  const call_updateTxnTb = useAPI(updateTxnTb);

  const onSubmit = async (values) => {
    const tmpValues = {
      ...values,
      ...c_lightData,
      ...s_editData,
      sqty: parseInt(values.sqty),
    };
    call_updateTxnTb.request(tmpValues);
  };

  useEffect(() => {
    call_getAllWhID.request();
  }, []);

  useEffect(() => {
    if (call_getAllWhID.status === "err") {
      message.error(call_getAllWhID.msg);
    }
  }, [call_getAllWhID.status]);

  useEffect(() => {
    if (call_updateTxnTb.status === "suc") {
      message.success(call_updateTxnTb.msg);
      set_s_showModal(false);
    } else if (call_updateTxnTb.status === "err") {
      message.error(call_updateTxnTb.msg);
    }
  }, [call_updateTxnTb.status]);

  return (
    <Form form={form} initialValues={{
      isvalid: true,
      ...s_editData
    }} onFinish={onSubmit} layout="vertical">
      <Row gutter={[12, 12]}>
        <Col span={6}>
          <Form.Item
            //料號
            label={t("util.util.PN")} name="PN">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            //過帳型態
            label={t("util.util.idmtype")} name="idmtype">
            <Select
              disabled
              options={[
                { label: t("util.util.idmtype_s"), value: "S" },
                { label: t("util.util.idmtype_d"), value: "D" },
                { label: t("util.util.idmtype_m"), value: "M" },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            //倉別
            label={t("util.util.whID")} name="whID" rules={[{ required: true }]}>
            <Select
              allowClear
              options={
                call_getAllWhID.data?.map((x) => ({
                  label: x.whNM,
                  value: x.whID,
                })) || []
              }
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            //人工輸入
            label={t("util.util.sqty")} name="sqty" rules={[{ required: true }]}>
            <InputNumber className="w-full" />
          </Form.Item>
        </Col>

        <Divider />
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_showModal(false)}>{t("util.util.cancel")}</Button>
            <Button type="primary" htmlType="submit"
            loading={call_updateTxnTb.status==="load"}>
              {t("util.util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default ModalContainer;
