
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Header from "./Header";
import TbContainer from "./TbContainer";
import Footer from "./Footer";

// forwardRef包起來的Component才會有ref屬性可以向父層傳遞
const Print4260 = forwardRef((props, Print_ref) => {
	const { hd_values, s_printType, s_tbData, set_s_showLqModal } = props;
	const printRef = useRef(null);
	// console.log("s_tbData = ", s_tbData);

	// 子元件屬性向上傳遞用的hook引入
	useImperativeHandle(Print_ref, () => ({
		Print_helf: (type) => {
			Print_helf();
		},
	}));

	// 設置列印內容
	const Print_helf = useReactToPrint({
		// onBeforePrint: () => {
		//   console.log("BeforePrint_Cut",trendData);
		// },
		content: () => {
			// 返回 printRef.current，也就是组件中 printRef 引用指向的部分
			// 打印由 printRef 指定的内容，這裡printRef 绑定到整个 Print 组件
			return printRef.current;
		},
		// 設定列印頁的css
		bodyClass: "print-style",
		pageStyle: () => `
            body{
            -webkit-print-color-adjust: exact; 
            overflow: visible;
            }
            @page {
            size: ${s_printType.width} ${s_printType.height} portrait;
            }
        `,
	});
	// size:A4 portrait;
	// size: 21.9cm 13.9cm;
	// 一頁最多有幾筆明細(不含價格趨勢)
	const page_length = s_printType.height === "12.9cm" ? 8 : 19;
	const pages = Math.ceil(s_tbData.length / page_length);

	// 將一個陣列按指定的頁面大小分割成多個部分
	// array分頁的陣列，pageSize每頁的條目數
	const getPages = (array, pageSize) => {
		// console.log("array = ", array);
		// prev 是累積結果，curr 是當前元素，index 是索引
		return array.reduce((prev, curr, index) => {
			// 計算當前明細屬於第幾頁
			const page = Math.floor(index / pageSize) + 1;
			// 計算當前頁的資料陣列
			// 如果 prev 中已經有該頁的數據，則取出該頁的數據，否則創建一個空數組。
			const data = prev[page - 1] ? prev?.[page - 1] : [];
			// 將當前元素加入當前頁的資料陣列
			// curr.itemno = index + 1;
			prev[page - 1] = [...data, curr];
			// prev[page - 1] = { currentPage: page, data: [...data, curr] };
			return prev;
		}, []);
	};
	// 用 getPages 函數來將 s_tbData 分頁，並得到已分頁的數據結構
	const renderdata = getPages(s_tbData, page_length);
	// console.log("renderdata = ", renderdata);


	return (
		<div
			id="content1"
			ref={printRef}
			// style={{ minHeight: `${ renderdata.length * 100 } vh` }} // 不用 Tailwind 是因為 Tailwind 不支持變數的寫法
			style={{
				// height: s_printType.height,
				// width:s_printType.width,
				// width:s_printType.width,
				// height: s_printType.height,
				// height: `${renderdata.length * 12.9} vh`,
				margin: "9mm 0mm 0mm 20mm",
				display: "flex",
				flexDirection: "column",
				// justifyContent: "space-between",
				// alignItems: "center",
				gap: "1cm",
			}}
		>
			{renderdata?.map((item, index) => (
				<section className="flex flex-col">
					<div
						style={{
							// border: "1px solid #000", // 勿動
							//  backgroundColor: "red",
							//  height: "100%",
							//   width: "100%",
							width: s_printType.width,
							height: s_printType.height,
							display: "flex",
							flexDirection: "column",

							pageBreakAfter: "always",
							//  justifyContent: "start",
							//  alignItems: "center",
						}}
					>
						<Header hd_values={hd_values} pages={pages} currentPage={index + 1} />
						<TbContainer hd_values={hd_values} s_tbData={item} />
						<Footer />
					</div>
					{/* <div
                        style={{
                            width: s_printType.width,
                            height: s_printType.height,
                        }}>
                        <Footer />
                    </div> */}
				</section>
			))}
		</div>
	);
});
{
	/* <div style={{
			  //  border: "1px solid #000", // 勿動
			  //  backgroundColor: "red",
			   height: "100%",
				width: "100%",
			   display: "flex",
			   flexDirection: "column",
			  //  justifyContent: "start",
			  //  alignItems: "center",
			}}>
			   <Header hd_values={hd_values}/>
			   <TbContainer hd_values={hd_values} s_tbData={s_tbData} />
			   <Footer />
			</div> */
}

export default Print4260;
