import React from "react";
import { useTranslation } from "react-i18next";

const TITLE_STYLE = "text-md font-bold flex-center";
const BORDER = "border-0 border-solid";

//page-break-after: always
const Footer = () => {
	const { t } = useTranslation();

	const SING_LABEL = [
		{ title: "會計聯" },
		{ title: "採購聯" },
		{ title: "庫房上架" },
		{ title: "收料建單" },
	];
	return (
		<>
			<section className="flex gap-[20px] w-full">
				{SING_LABEL.map((item, i) => (
					<div key={i} className="flex flex-col items-center flex-1">
						<span className={TITLE_STYLE}>{item.title}</span>
						<span className={`h-10 w-full ${BORDER} border-b`} />
						<span className="text-[12px]">Nama Jelas</span>
					</div>
				))}
			</section>
			<div className="flex flex-center gap-[50px] text-[12px] mt-2">
				<i>Asli & Kuning : Accounting.</i>
				<i>Hijau : Warehouse.</i>
				<i>Merah : Customer.</i>
				<i>Biru : Secunty</i>
			</div>
		</>

	);
};

export default Footer;
