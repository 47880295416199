import React from "react";
import { useTranslation } from "react-i18next";
import Barcode from "react-jsbarcode";

const TITLE_STYLE = "text-[12px] font-bold flex-center border-0 border-solid border-y py-1";
const BORDER = "border-0 border-solid";

const WIDTH = {
    itemno: "w-[3%]",
    PN: "w-[24%]",
    pdtNM: "w-[25%]",
    unit: "w-[7%]",
    pdtspec: "w-[9%]",
    barcode: "w-[32%]",
};

const TbContainer = ({ hd_values, s_tbData }) => {
    const { t } = useTranslation();

    return (
        <section className="flex-1 flex flex-col">
            <div className=" border-0 border-solid border-l w-full flex mb-2">
                {/* 項序 */}
                <span className={`${WIDTH.itemno} border-r ${TITLE_STYLE}`}>{t("IMS.plan-out.printN")}</span>
                <span className={`${WIDTH.PN} border-r ${TITLE_STYLE}`}>{t("util.util.PN")}</span>
                <span className={`${WIDTH.pdtNM} border-r ${TITLE_STYLE}`}>{t("IMS.util.describe")}</span>
                <span className={`${WIDTH.unit} border-r ${TITLE_STYLE}`}>{t("IMS.util.unit")}</span>
                <span className={`${WIDTH.pdtspec} border-r ${TITLE_STYLE}`}>{t("IMS.util.planqty")}</span>
                <span className={`${WIDTH.barcode} border-r ${TITLE_STYLE}`}>{t("IMS.plan-out.printMNB")}</span>
            </div>
            <section className={`flex flex-col ${BORDER} border-t`}>
                {s_tbData?.map((item, i) => (
                    <div key={i} className={`flex items-center w-full text-[12px] ${BORDER} border-b`}>
                        <span className={`h-7 ${WIDTH.itemno} flex-center ${BORDER} border-x`}>
                            {item.itemno}
                        </span>
                        <span className={`${WIDTH.PN} flex-start p-1`}>{item.PN}</span>
                        <span className={`h-7 ${WIDTH.pdtNM} flex-center ${BORDER} border-x`}>{item.pdtNM}</span>
                        <span className={`h-7 flex flex-center  ${WIDTH.unit} ${BORDER} border-r`}>
                            {item.itemno}
                        </span>
                        <span className={`h-7 pr-1 flex items-center justify-end ${WIDTH.pdtspec} ${BORDER} border-r`}>
                            {item.planqty}
                        </span>
                        <span className={`h-7 flex-center ${WIDTH.barcode} ${BORDER} border-r`}>
                            <Barcode
                                value={item.PN}
                                options={{
                                    displayValue: false,
                                    width: 1,
                                    height: 20,
                                    margin: 0
                                }}
                            />
                        </span>
                    </div>
                ))}
            </section>
        </section>
    );
};

export default TbContainer;
