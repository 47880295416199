import { Alert, Button, Drawer, Input, Modal, Space, message } from "antd";
import dayjs from "dayjs";
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
	forwardRef,
	useContext,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";

import SystemContext from "@/components/Context/SystemContext";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { SeeMoreButton } from "@/components/ERP/Button";
import { IconCircle, IconGarbage } from "@/components/Icon/Action";
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import { IconPrinter } from "@/components/Icon/WOS";
import useAPI from "@/hooks/useAPI";
import { deleteTxnHd, getTxnHd, getTxnTb, txn } from "@/service/apis/WMS/WMS";
import { formatAmount } from "@/util/format";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import ModalContainer from "./ModalContainer";
import OverRequisitionDrawer from "./OverRequisitionDrawer/index"; //超領單
import Print4210 from "./Print4210"; // 生產領用
import Print4260 from "./Print4260"; // 生產超領
import RequisitionDrawer from "./RequisitionDrawer"; //領料單

const In = forwardRef((props, ref) => {
	const { c_lightData, set_c_lightData, c_searchData } = props;
	const { c_systemConfig } = useContext(SystemContext);
	const { t } = useTranslation();
	const Print_ref = useRef(null);

	const [s_showModal, set_s_showModal] = useState(false); // 是否開啟編輯庫房入庫的對話框
	const [s_tableData, set_s_tableData] = useState([]);
	// 列印用_取得表身資料
	const [s_printType, set_s_printType] = useState({ width: "19.6cm", height: "12.9cm" });
	const call_getTxnTb = useAPI(getTxnTb);

	// 控制抽屜開啟、關閉、抽屜要顯示的資料
	const [s_drawer, set_s_drawer] = useState({ show: false, data: {} });

	// 列印抽屜的表頭資料
	const [s_printData, set_s_printData] = useState({});
	const [s_tbData, set_s_tbData] = useState([]); // 列印抽屜的表身資料

	// 後端的資料
	const [s_pageInfo, set_s_pageInfo] = useState({
		currentPage: 0,
		endItemNumber: 0,
		pageItemNumber: 0,
		startItemNumber: 0,
		totalItems: 0,
	});

	// 送給後端的資料
	const [s_searchValue, set_s_searchValue] = useState({
		page: 1,
		pageSize: 200,
	});

	const opcode_Drawer = [
		{ opcode: "4210", drawer: <RequisitionDrawer /> }, // 生產領用
		{ opcode: "4260", drawer: <OverRequisitionDrawer /> }, // 生產超領
	];

	// 列印用_列印props
	const printerProps = {
		ref: Print_ref,
		hd_values: s_printData,
		s_tbData: s_tbData,
		s_printType: s_printType,
	};

	// 列印用_依據opcode顯示對應列印單
	const opcode_Printer = [
		{
			opcode: "4210",
			printer: <Print4210 {...printerProps} />,
		}, // 生產領用
		{
			opcode: "4260",
			printer: <Print4260 {...printerProps} />,
		}, // 生產超領
	];

	// 查看更多
	const openDetail = (event, rowData) => {
		set_s_drawer({ show: true, data: rowData });
		event.stopPropagation();
	};

	// 判斷要顯示哪個drawer
	const getDrawerByOpcode = (opcode) => {
		const matchedItem = opcode_Drawer.find((item) => item.opcode === opcode);
		return matchedItem ? matchedItem.drawer : null;
	};

	// 列印用_判斷要顯示哪個printer
	const getPrinterByOpcode = (opcode) => {
		const matchedPrint = opcode_Printer.find((item) => item.opcode === opcode);
		return matchedPrint ? matchedPrint.printer : null;
	};

	const call_getTxnHd = useAPI(getTxnHd);
	const call_txn = useAPI(txn);
	const call_deleteTxnHd = useAPI(deleteTxnHd);
	// console.log(`IMS.util.preN_${c_searchData.opcode}`);

	// 列印用_需要新的列印資料時，從後端獲取相應的表身數據，確保列印使用最新的資料
	useEffect(() => {
		// 檢查s_printData中是否有資料
		if (Object.keys(s_printData).length > 0) {
			// 發送請求，s_printData作為參數
			call_getTxnTb.request(s_printData);
		}
		// console.log(s_printData)
	}, [s_printData]);

	// 列印用_根據表身的資料狀態執行
	useEffect(() => {
		// 從call_getTxnTb提取data、status和msg。status => load, err, suc
		const { data, status, msg } = call_getTxnTb;
		if (status === "suc") {
			// 調用set_s_tbData(data)將數據保存到狀態s_tbData中
			set_s_tbData(data);
			// console.log(s_tbData)
		} else if (status === "err") {
			set_s_tbData([]);
		}
	}, [call_getTxnTb.status]);


	// 列印用_設置列印尺寸選擇
	const printer = (rowData) => {
		Modal.info({
			// title: "請選擇列印尺寸",
			title: t("util.util.placeholder_select") + t("APS.aps-manage.print_size"),
			content: (
				<div className="flex justify-center gap-2">
					<Button
						className="flex-1 h-10"
						// disabled
						type="primary"
						onClick={() => {
							set_s_printType({ width: "19.6cm", height: "12.9cm" });

							Modal.destroyAll();

							setTimeout(() => {
								Print_ref.current.Print_helf();
							}, 1000);
						}}
					>
						A5
					</Button>

					<Button
						className="flex-1 h-10"
						// disabled
						type="primary"
						onClick={() => {
							set_s_printType({ width: "19.6cm", height: "25.8cm" });

							Modal.destroyAll();

							setTimeout(() => {
								Print_ref.current.Print_helf();
							}, 1000);
						}}
					>
						A4
					</Button>
				</div>
			),
			okText: <IconConfirm />, // 確定
		});
	};


	const tableColumns = [
		{
			title: t("IMS.util.opcode"), // 單別
			dataIndex: "opcode",
			align: "center",
			render: (text) => t(`IMS.util.opcode_${text}`),
		},
		{
			title: t("IMS.util.jobN"), // 作業單號
			dataIndex: "jobN",
			align: "center",
		},
		{
			title: t(`IMS.util.preN_${c_searchData.opcode}`),
			dataIndex: "woN",
			align: "center",
		},
		{
			title: t("IMS.util.planqty"), // 計畫數量
			dataIndex: "planqty",
			align: "right",
			render: (text) => formatAmount(text, 2),
		},
		{
			title: t("IMS.util.realtqty"), // 實際數量
			dataIndex: "realqty",
			align: "right",
			render: (text) => formatAmount(text, 2),
		},
		{
			title: t("IMS.util.bal"), // 帳差
			dataIndex: "bal",
			align: "right",
			render: (text) => formatAmount(text, 2),
		},
		{
			title: t("util.util.lifeF"), // 歷程
			dataIndex: "lifeF",
			align: "center",
			render: (text) => t(`IMS.util.lifeF_${text}`),
			onCell: (rowData) => ({ className: `lifeF-${rowData.lifeF}` }),
		},
		{
			title: t("util.util.updtT"), // 更新時間
			dataIndex: "lifeFT",
			align: "center",
			render: (text) => dayjs(text).format("MM-DD HH:mm"),
		},
		{
			title: t("util.util.action"), // 操作
			dataIndex: "render",
			align: "center",
			// 當前行資料和整個行資料
			render: (_, rowData) => (
				<Space>
					<IconPrinter
						disabled={rowData.lifeF === "T" ? false : true}
						onClick={(e) => {
							set_s_printData(rowData);
							// set_s_showLqModal(true);
							printer(rowData);
						}}
					/>
					<IconCircle disabled={rowData.lifeF !== "2"} onClick={(e) => recycleTxn(e, rowData)} />
					{/* 查看更多 */}
					<SeeMoreButton onClick={(e) => openDetail(e, rowData)} />
					<IconGarbage disabled={rowData.lifeF !== "0"} onClick={(e) => deleteTxn(e, rowData)} />
				</Space>
			),
		},
	];

	useImperativeHandle(ref, () => ({
		Create: () => set_s_showModal(true), //開啟新增Modal
		Call_hd: () => call_getTxnHd.request({ ...c_searchData, ...s_searchValue }),
	}));

	const recycleTxn = (e, rowData) => {
		Modal.confirm({
			title: t("IMS.util.txn_confirm"),
			icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
			content: t("IMS.util.txn_confirm_msg"),
			okText: t("util.util.ok"),
			onOk: () => call_txn.request(rowData),
			cancelText: t("util.util.cancel"),
		});

		e.stopPropagation(); // 這裡不要開啟表身
	};

	const deleteTxn = (e, rowData) => {
		Modal.confirm({
			title: t("util.util.del_confirm"),
			icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
			content: t("util.util.del_confirm_msg"),
			okText: t("util.util.ok"),
			onOk: () => call_deleteTxnHd.request(rowData),
			cancelText: t("util.util.cancel"),
		});

		e.stopPropagation(); // 這裡不要開啟表身
	};

	// c_searchData 變更時改變 page
	useEffect(() => {
		// console.log("c_searchData === ", c_searchData);
		set_c_lightData({});
		set_s_searchValue((prev) => ({ ...prev, page: 1 }));
	}, [c_searchData]);

	useEffect(() => {
		call_getTxnHd.request({ ...c_searchData, ...s_searchValue });
	}, [s_searchValue]);

	useEffect(() => {
		if (call_getTxnHd.status === "suc") {
			const { tableData, pageInfo } = call_getTxnHd.data;
			// const newTableData = tableData.map((x, i) => ({ ...x, key: x.planN, index: i }));
			const newTableData = tableData.map((x, i) => ({ ...x, key: x.opUUID, index: i }));
			set_s_tableData(newTableData);
			set_s_pageInfo(pageInfo);

			// 更新 c_lightData
			if (c_lightData.planN && !newTableData.some((x) => x.planN === c_lightData.planN)) {
				set_c_lightData({});
			} else {
				const thisData = newTableData.find((x) => x.planN === c_lightData.planN);
				set_c_lightData(thisData ? { ...thisData, key: thisData.planN } : {});
			}
			message.success(call_getTxnHd.msg);
		} else if (call_getTxnHd.status === "err") {
			set_s_tableData([]);
			message.error(call_getTxnHd.msg);
		}
	}, [call_getTxnHd.status]);

	useEffect(() => {
		if (call_txn.status === "suc") {
			message.success(call_txn.msg);
			call_getTxnHd.request({ ...c_searchData, ...s_searchValue });
		} else if (call_txn.status === "err") {
			message.error(call_txn.msg);
		}
	}, [call_txn.status]);

	useEffect(() => {
		if (call_deleteTxnHd.status === "suc") {
			message.success(call_deleteTxnHd.msg);
			call_getTxnHd.request({ ...c_searchData, ...s_searchValue });
		} else if (call_deleteTxnHd.status === "err") {
			message.error(call_deleteTxnHd.msg);
		}
	}, [call_deleteTxnHd.status]);

	return (
		<>
			<CustomTable
				columns={tableColumns.filter((x) => x.hidden !== true)}
				dataSource={s_tableData}
				loading={call_getTxnHd.status === "load"}
				tbMode={Object.keys(c_lightData).length > 0}
				c_lightData={c_lightData}
				onRow={(record) => ({
					onClick: () =>
						record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
				})}
				pagination={{
					total: s_pageInfo.totalItems,
					current: s_searchValue.page,
					pageSize: s_searchValue.pageSize,
					onChange: (current, size) =>
						set_s_searchValue((prev) => ({
							...prev,
							page: prev.pageSize !== size ? 1 : current,
							pageSize: size,
						})),
				}}
			/>

			<CustomModal
				title={
					Object.keys(c_lightData).length > 0
						? `${t("util.util.edit")}${t(`IMS.util.opcode_${c_searchData.opcode}`)}`
						: `${t("util.util.add")}${t(`IMS.util.opcode_${c_searchData.opcode}`)}`
				}
				width="70%"
				open={s_showModal}
				onCancel={() => set_s_showModal(false)}
				afterClose={() => {
					set_c_lightData({});
					call_getTxnHd.request({ ...c_searchData, ...s_searchValue });
				}}
			>
				<ModalContainer
					opcode={c_searchData.opcode}
					set_s_showModal={set_s_showModal}
					s_editData={c_lightData}
				/>
			</CustomModal>

			{/* 列印抽屜 */}
			<Drawer placement="left" closable={false} width={0} height={1000} open={true} mask={false}>
				{getPrinterByOpcode(c_searchData.opcode)}
			</Drawer>

			<Drawer
				placement="right"
				closable={false}
				width={820}
				onClose={() => set_s_drawer({ show: false, data: {} })}
				open={s_drawer.show}
			>
				{/* {getDrawerByOpcode(c_searchData.opcode)} */}
			</Drawer>
		</>
	);
});

export default In;
